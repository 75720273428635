import { Grid, makeStyles, Typography, Link } from "@material-ui/core";
import { Https } from "@material-ui/icons";
import { LINK_WHATSAPP } from "App";
import botFace from "assets/BOT_FACE.png";
import botIg from "assets/BOT_IG.png";
import botTwitter from "assets/BOT_TWITTER.png";
import botWhats from "assets/BOT_WHATS.png";
import botYt from "assets/BOT_YOUTUBE.png";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "20px",
        backgroundColor: "white",
    },
    footer: {
        padding: theme.spacing(0.5),
        "& img": {
            height: "45px",
            padding: theme.spacing(0.5),
        },
    },
    copyright: {
        marginTop: "15px",
        fontSize: "10px",
    }
}));

export const AppFooter = () => {
    const classes = useStyles();

    return (
        <>
        <Grid container justify="space-evenly" className={classes.root}>
            <Grid item>
                <Typography variant="body2">Nossas Redes</Typography>
                <BotoesRedesSociais />
            </Grid>
            <Grid item>
                <Typography variant="body2">Site Seguro</Typography>
                <Grid container alignItems="center" style={{ marginTop: "10px" }}>
                    <Grid item>
                        <Https fontSize="large" />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">SSL</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="body2" style={{ paddingTop: "12px" }}>
                <Link href="termos-privacidade" color="inherit">Privacidade e Proteção de Dados</Link>
                </Typography>                
                <Typography variant="body2" style={{ paddingTop: "10px" }}>
                <Link href="termos-condicoes" color="inherit">Termos e Condições de Uso</Link>
                </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography align="center" className={classes.copyright} >
                © 2021 Proteja Seu Pet
            </Typography>
            </Grid>
        </Grid>
        
    </>
    );
};

export const BotoesRedesSociais = () => {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            <a href="https://facebook.com/protejaseupets" target="_blank" rel="noreferrer">
                <img src={botFace} alt="Facebook"></img>
            </a>
            <a href="https://instagram.com/protejaseupets" target="_blank" rel="noreferrer">
                <img src={botIg} alt="Instagram"></img>
            </a>
            <a href="https://twitter.com/protejaseupets" target="_blank" rel="noreferrer">
                <img src={botTwitter} alt="Twitter"></img>
            </a>
            <a href={LINK_WHATSAPP} target="_blank" rel="noreferrer">
                <img src={botWhats} alt="Whats App"></img>
            </a>
            <a href="https://www.youtube.com/watch?v=Yoc2sRtBUlc" target="_blank" rel="noreferrer">
                <img src={botYt} alt="YouTube"></img>
            </a>
        </div>
    );
};
