import { Box, Button, Card, CardContent, CircularProgress, Container, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useResgateContext } from "App.routes";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "services/axios-utils";
import { formatSexo } from "services/utils";
import { CadastroStepper } from "./ResgatarGiftCard";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: theme.spacing(2),
        "& button": {
            marginLeft: theme.spacing(3),
        },
    },
    card: {
        minWidth: "500px",
    },
}));

export default function ConfirmarResgate() {
    const classes = useStyles();
    const history = useHistory();
    const [usuario, setUsuario] = useState();
    const [loading, setLoading] = useState(false);
    const { pet, giftCard } = useResgateContext();

    useEffect(() => {
        axios.get(`/usuario`).then((result) => {
            setUsuario(result.data);
        });
    }, []);

    const voltar = () => {
        history.goBack();
    };

    const formataEndereco = () => {
        const endereco = usuario.endereco;
        let descEndereco = `${endereco.logradouro}, ${endereco.numero}`;
        if (endereco.complemento) {
            descEndereco += ` - ${endereco.complemento}`;
        }
        return descEndereco;
    };

    const formataCep = () => {
        const cep = usuario.endereco.cep.toString();
        return cep.substr(0, 5) + "-" + cep.substr(5);
    };

    const confirmar = () => {
        setLoading(true);
        const entrada = { giftCard, pet: { nome: pet.nome, raca: pet.raca, sexo: pet.sexo, nascimento: pet.nascimento } };
        axios
            .post("gift-card/resgatar", entrada)
            .then(() => {
                history.push("resgate-gift-card-realizado");
            })
            .catch((err) => {
                setLoading(false);
                console.log({ err });
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Ocorreu um erro ao confirmar o resgate, por favor entre em contato com o suporte.");
                }
            });
    };

    return (
        <Container component="main" maxWidth="md">
            <CadastroStepper activeStep={3} />
            <div className={classes.paper}>
                {usuario ? (
                    <Card className={classes.card} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" gutterBottom align="center">
                                Confirma os dados abaixo?
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                Produto
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {giftCard.produto.nome}
                            </Typography>

                            <Typography variant="caption" color="textSecondary">
                                Gift Card
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {giftCard.serial}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                Cliente
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {usuario.nome} - CPF {usuario.cpf}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                Endereço
                            </Typography>
                            <Typography variant="body1">{formataEndereco()}</Typography>
                            <Typography variant="body1">
                                {usuario.endereco.cidade} - {usuario.endereco.uf}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {formataCep()}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                Pet
                            </Typography>
                            <Typography variant="body1">{pet.nome}</Typography>
                            <Typography variant="body1">
                                {pet.raca.nome} - {formatSexo(pet.sexo)}
                            </Typography>
                            <Typography variant="body1">Nascido em {format(pet.nascimento, "dd/MM/yyyy")}</Typography>
                        </CardContent>
                        <Divider />
                        <Box className={classes.actions}>
                            <Button variant="outlined" color="primary" onClick={voltar}>
                                Voltar
                            </Button>
                            <Button variant="contained" color="primary" onClick={confirmar} disabled={loading}>
                                Confirmar
                                {loading && <CircularProgress size={24} style={{ marginLeft: 4 }} />}
                            </Button>
                        </Box>
                    </Card>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </Container>
    );
}
