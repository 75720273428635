import { Avatar, Button, CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import TextFieldDefault from "components/TextFieldDefault/TextFieldDefault";
import { Formik } from "formik";
import { useQuery } from "hooks/useQuery";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "services/user-service";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        marginTop: theme.spacing(1),
    },
}));

const validationSchema = Yup.object({
    codigo: Yup.string().required(),
    senha: Yup.string().min(8).required(),
});

const initialValues = {
    codigo: "",
    senha: "",
};

export default function ConfirmarEsqueciSenha() {
    const classes = useStyles();
    const [loading, setLoading] = useState();
    const history = useHistory();
    const email = useQuery().get("email");

    const onSubmit = (values) => {
        const user = new User(email);

        setLoading(true);
        user.confirmPassword(values.codigo, values.senha)
            .then((res) => {
                toast.success("Senha trocada com sucesso.");
                history.push("/login");
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Ocorreu um erro ao confirmar.");
                throw err;
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5" gutterBottom>
                    Esqueci minha senha
                </Typography>
                <div>
                    <Typography variant="subtitle1" color="textSecondary">
                        Um e-mail foi enviado com o código para trocar sua senha. Digite-o abaixo para confirmar, e em seguida sua nova
                        senha.
                    </Typography>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur={false}>
                    {(formik) => (
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextFieldDefault label="Código" name="codigo" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldDefault label="Senha" name="senha" type="password" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldDefault label="Confirme a senha" name="confirmasenha" type="password" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" disabled={loading} fullWidth variant="contained" color="primary">
                                        Trocar senha
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
                {loading && <CircularProgress size={24} />}
            </div>
        </Container>
    );
}
