import { Box, Card, CardContent, CardHeader, Chip, CircularProgress, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { format, parse, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "services/axios-utils";
import { formatSexo } from "services/utils";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        maxWidth: "767px",
    },
    loader: {
        width: 500,
    },
    cardAction: {
        margin: 0,
    },
}));

export default function MeusCodigos() {
    const classes = useStyles();
    const [lista, setLista] = useState();

    useEffect(() => {
        axios
            .get("pedidos/usuario")
            .then((result) => {
                setLista(result.data);
            })
            .catch((error) => {
                toast.error("Erro ao listar.");
                throw error;
            });
    }, []);

    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    Gift Cards já resgatados
                </Typography>
                {!lista ? (
                    <Box textAlign="center" marginTop={8}>
                        <CircularProgress></CircularProgress>
                    </Box>
                ) : lista.length === 0 ? (
                    <Alert elevation={4} severity="error">
                        Você ainda não resgatou nenhum Gift Card
                    </Alert>
                ) : (
                    lista
                        .sort((a, b) => b.criadoEm.localeCompare(a.criadoEm))
                        .map((row, idx) => (
                            <Card key={idx} variant="outlined" style={{ marginBottom: 8 }}>
                                <CardHeader
                                    action={row.statusContrato && <Chip label={row.statusContrato}></Chip>}
                                    title={row.giftCard.produto.nome}
                                    subheader={row.giftCard.serial}
                                    classes={{ action: classes.cardAction }}
                                />
                                <CardContent>
                                    {row.inicioVigencia && (
                                        <>
                                            <Typography variant="caption" color="textSecondary">
                                                Vigência
                                            </Typography>
                                            <Typography variant="body1">
                                                de {format(parseISO(row.inicioVigencia), "dd/MM/yyyy")} até{" "}
                                                {format(parseISO(row.finalVigencia), "dd/MM/yyyy")}
                                            </Typography>
                                            <br />
                                        </>
                                    )}
                                    <Typography variant="caption" color="textSecondary">
                                        Pet
                                    </Typography>
                                    <Typography variant="h6">{row.pet.nome}</Typography>
                                    <Typography>
                                        {row.pet.raca.nome} - {formatSexo(row.pet.sexo)}
                                    </Typography>
                                    <Typography gutterBottom>
                                        Nascimento em {format(parse(row.pet.nascimento, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}
                                    </Typography>
                                    <br />
                                    <Typography variant="body2" color="textSecondary">
                                        {`Gift card resgatado em ${format(parseISO(row.criadoEm), "dd/MM/yyyy HH:mm")}`}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                )}
            </div>
        </Container>
    );
}
