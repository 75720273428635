import { AppBar, Box, Button, makeStyles, Toolbar, useMediaQuery, useTheme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import iconGift from "assets/ICON_GIFT.png";
import logo from "assets/LOGO_CABECALHO.png";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useUsuarioLogado } from "../App";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        boxShadow: "none",
        backgroundColor: "white",
        height: "125px",
    },
    toolbarRoot: {
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },
    },
    logo: {
        margin: theme.spacing(1),
        height: "90px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            height: "44px",
        },
    },
    active: {
        backgroundColor: grey[300],
    },
    parceiro: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        position: "fixed",
        borderRadius: "50%",
        top: "60px",
        right: "16px",
        height: "100px",
        width: "130px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        boxShadow: theme.shadows[10],
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.3)",
        "& h6": {
            lineHeight: 1.3,
        },
        cursor: "pointer",
    },
    giftButton: {
        width: "30px",
        marginRight: "10px",
    },
    cabecalho: {
        display: "flex",
        width: "100%",
        alignItems: "inherit",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    botoesCabecalho: {
        display: "flex",
        flex: "1",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(1),
        },
    },
}));

export const AppToolbar = ({ setUsuario }) => {
    const user = useUsuarioLogado();
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();

    const path = location.pathname;

    const sair = () => {
        user.signOut();
        setUsuario(undefined);
        history.push("/");
    };

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));

    const isInicio = path === "/";
    const isSobre = path === "/sobre";
    const isPlanos = path === "/planos";
    const isResgatar = path === "/gift-card";
    const isMeusCodigos = path === "/meus-codigos";

    const buttonVariant = (active) => {
        return active ? "contained" : "text";
    };
    const buttonColor = (active) => {
        return active ? "default" : "primary";
    };

    return (
        <>
            <AppBar position="fixed" className={classes.toolbar}>
                {process.env.REACT_APP_ENV === "development" ? (
                    <div
                        style={{
                            backgroundColor: "red",
                            width: "100%",
                            color: "white",
                            fontSize: 10,
                            textAlign: "center",
                            display: "block",
                            clear: "both",
                        }}
                    >
                        <b>### Ambiente desenvolvimento ###</b>
                    </div>
                ) : (
                    ""
                )}
                <Toolbar classes={{ root: classes.toolbarRoot }}>
                    <Box className={classes.cabecalho}>
                        <img src={logo} alt="logo" className={classes.logo} onClick={() => history.push("/")}></img>
                        <Box className={classes.botoesCabecalho}>
                            <Button
                                component={RouterLink}
                                disableElevation
                                to="/"
                                variant={buttonVariant(isInicio)}
                                color={buttonColor(isInicio)}
                                size={small ? "small" : "medium"}
                            >
                                Inicio
                            </Button>
                            <Button
                                component={RouterLink}
                                to="sobre"
                                disableElevation
                                variant={buttonVariant(isSobre)}
                                color={buttonColor(isSobre)}
                                size={small ? "small" : "medium"}
                            >
                                Sobre
                            </Button>
                            <Button
                                component={RouterLink}
                                to="planos"
                                disableElevation
                                variant={buttonVariant(isPlanos)}
                                color={buttonColor(isPlanos)}
                                size={small ? "small" : "medium"}
                            >
                                Planos
                            </Button>
                            {user && (
                                <Button
                                    component={RouterLink}
                                    to="meus-codigos"
                                    disableElevation
                                    variant={buttonVariant(isMeusCodigos)}
                                    color={buttonColor(isMeusCodigos)}
                                    size={small ? "small" : "medium"}
                                >
                                    Resgates
                                </Button>
                            )}
                            <Button
                                component={RouterLink}
                                to="gift-card"
                                disableElevation
                                variant={buttonVariant(isResgatar)}
                                color={buttonColor(isResgatar)}
                                size={small ? "small" : "medium"}
                            >
                                <img src={iconGift} alt="gift card button" className={classes.giftButton}></img>
                                Gift Card
                            </Button>
                            {user && (
                                <Box ml={1}>
                                    <Button variant="outlined" color="inherit" onClick={sair} size={small ? "small" : "medium"}>
                                        Sair
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <div style={{ marginBottom: "90px", clear: "both" }}></div>
        </>
    );
};
