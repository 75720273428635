import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
}));

export default function Sobre() {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                    Quem Somos
                </Typography>
                <Typography variant="body1" align="justify" color="textSecondary" paragraph>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A <b>Proteja Seu Pet</b> é uma empresa focada no atendimento de seu pet, deu-se
                    início a empresa, pensando em uma forma prática e fácil de acesso e sem burocracia, carências ou dificuldades no
                    entendimento do produto. Buscamos uma forma completa de atender você e seu pet, conseguimos unir em um único produto as
                    maiores e melhores coberturas. Aqui tratamos seu pet com o devido respeito e carinho que ele merece, fizemos este
                    produto pensando no seu pet como sendo de nossa família!
                </Typography>
            </div>
        </Container>
    );
}
