import { CssBaseline } from "@material-ui/core";
import { makeStyles, ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { AppRouter } from "App.routes";
import { createBrowserHistory } from "history";
import { AppFooter } from "pages/AppFooter";
import { AppToolbar } from "pages/AppToolbar";
import { createContext, useContext, useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { User } from "services/user-service";
import "./App.css";
import * as Sentry from "@sentry/browser";

const theme = createMuiTheme({
    typography: {
        fontFamily: "Gotham, Roboto, Helvetica, Arial, 'sans-serif'",
        h1: {
            fontFamily: "Oduda",
        },
        h2: {
            fontFamily: "Oduda",
        },
    },
    palette: {
        primary: {
            main: "#0EBFD6",
            dark: "#0E959F",
        },
        secondary: {
            main: "#F4A22A",
        },
        text: {
            primary: "#0E959F",
        },
        contrastThreshold: 3,
    },
});
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    main: {
        flex: 1,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
    },
}));

export const UserContext = createContext();

export const useUsuarioLogado = () => {
    const user = useContext(UserContext);
    return user;
};

export const history = createBrowserHistory();

function App() {
    const classes = useStyles();
    const [usuario, setUsuario] = useState();

    useEffect(() => {
        new User().checkIfAlreadyAuthenticated((user) => {
            if (user) {
                setUsuario(user);
            }
        });
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "production" && usuario) {
            Sentry.configureScope((scope) => {
                scope.setUser({ email: usuario.email, username: usuario.nome, id: usuario.getCognitoId() });
            });
        }
    }, [usuario]);

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer hideProgressBar />
            <body className={classes.root}>
                <CssBaseline />
                <UserContext.Provider value={usuario}>
                    <Router history={history}>
                    
                    <header><AppToolbar setUsuario={setUsuario}></AppToolbar></header>
                    <main className={classes.main}><AppRouter setUsuario={setUsuario}></AppRouter></main>
                    <footer><AppFooter /></footer>
                    
                    </Router>
                </UserContext.Provider>
            </body>
        </ThemeProvider>
    );
}

export const LINK_WHATSAPP = "https://wa.me/5551995014845";

export default App;
