import { Avatar, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { useResgateContext } from "App.routes";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function ResgateGiftCardRealizado() {
    const classes = useStyles();
    const context = useResgateContext();

    useEffect(() => {
        context.setPet(null);
        context.setGiftCard(null);
    }, [context]);

    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <ThumbUpIcon />
                </Avatar>
                <Typography variant="h4" gutterBottom>
                    Gift Card resgatado com sucesso!
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Agora é só aguardar a ativação pela prestadora.
                </Typography>
                <br />
                <Button component={RouterLink} to="/" variant="outlined" color="primary">
                    Retornar ao inicio
                </Button>
            </div>
        </Container>
    );
}
