import { Box, Button, CircularProgress, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "services/user-service";
import { EsqueceuSenha } from "./EsqueceuSenha";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const LoginForm = ({ setUsuario, retorno }) => {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [loading, setLoading] = useState(false);
    const [esqueceuSenha, setEsqueceuSenha] = useState();

    const classes = useStyles();

    const login = (event) => {
        event.preventDefault();

        if (!email || !senha) {
            return;
        }
        const user = new User(email);

        setLoading(true);
        user.signIn(senha)
            .then((res) => {
                setUsuario(user);
                toast.success("Login realizado com sucesso");
                if (retorno) {
                    history.push(retorno);
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.code === "NotAuthorizedException") {
                    toast.error("Usuário ou senha incorretos");
                } else {
                    toast.error("Não foi possivel realizar o login");
                }
                throw err;
            });
    };

    const iniciarEsqueceuSenha = () => {
        setEsqueceuSenha(true);
    };

    if (esqueceuSenha) return <EsqueceuSenha classes={classes} setEsqueceuSenha={setEsqueceuSenha}></EsqueceuSenha>;

    return (
        <>
            <form onSubmit={login}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(ev) => setEmail(ev.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(ev) => setSenha(ev.target.value)}
                />
                <Button type="submit" disabled={loading} fullWidth variant="contained" color="primary" className={classes.submit}>
                    Logar
                </Button>
            </form>
            <Box textAlign="center">{loading && <CircularProgress size={24} />}</Box>
            <Typography variant="body2">
                <Link href="#" onClick={iniciarEsqueceuSenha}>
                    Esqueceu sua senha?
                </Link>
            </Typography>
        </>
    );
};
