import { useMediaQuery, useTheme } from "@material-ui/core";
import { Box, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import banner from "assets/banner/BANNER_PLANOS.png";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    img: {
        width: "100%",
    },
    imgPlanos: {
        width: "80%",
    },
    table: {
        borderCollapse: "inherit",
        "& tr td:nth-of-type(1)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    servico: {
        backgroundColor: theme.palette.action.hover,
        // width: "50%",
    },
    lite: {},
    premium: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    size: {
        // width: "25%",
    },
}));
export default function Planos() {
    const classes = useStyles();

    const planosObj = [
        { nome: "Funeral", lite: { qtd: 1, limite: "R$ 2.000" }, premium: { qtd: 1, limite: "R$ 2.000" } },
        { nome: "Cirurgia Veterinária", premium: { qtd: 1, limite: "R$ 5.000" } },
        { nome: "Exames de Imagens", premium: { qtd: 1, limite: "R$ 350" } },
        { nome: "Exames Laboratoriais", premium: { qtd: 1, limite: "R$ 350" } },
        { nome: "Limpeza de Cálculos Dentários", premium: { qtd: 1, limite: "R$ 350" } },
        { nome: "Cerimonial Pet", lite: { qtd: 1, limite: "R$ 500" }, premium: { qtd: 1, limite: "R$ 500" } },
        { nome: "Translado Funerário", lite: { qtd: 1, limite: "R$ 200" }, premium: { qtd: 1, limite: "R$ 200" } },
        { nome: "Consulta Veterinária", premium: { qtd: 1, limite: "R$ 200" } },
        { nome: "Serviço Leva e Traz", lite: { qtd: 2, limite: "R$ 200" }, premium: { qtd: 2, limite: "R$ 200" } },
        { nome: "Transporte de Animal Assistido", lite: { qtd: 2, limite: "R$ 150" }, premium: { qtd: 2, limite: "R$ 150" } },
        {
            nome: "Transporte e Estadia em Hotel",
            lite: { qtd: "2/4 Diárias", limite: "R$ 100" },
            premium: { qtd: "2/4 Diárias", limite: "R$ 100" },
        },
        { nome: "Aplicação de Vacina a Domicílio", lite: { qtd: 3, limite: "R$ 80" }, premium: { qtd: 3, limite: "R$ 80" } },
        { nome: "Implantação de Microchip", lite: { qtd: 2 }, premium: { qtd: 2 } },
        { nome: "Agendamento de consulta veterinária", lite: { qtd: 2 }, premium: { qtd: 2 } },
        { nome: "Serviço Pet Home Care", lite: { qtd: 2 }, premium: { qtd: 2 } },
        { nome: "Serviço de Apoio a Localização do Pet Desaparecido", lite: { qtd: "Ilimitado" }, premium: { qtd: "Ilimitado" } },
        { nome: "Informação em Caso de Viagem", lite: { qtd: "Ilimitado" }, premium: { qtd: "Ilimitado" } },
        { nome: "Indicações de Hotéis Pet", lite: { qtd: "Ilimitado" }, premium: { qtd: "Ilimitado" } },
        { nome: "Informações sobre Vacina", lite: { qtd: "Ilimitado" }, premium: { qtd: "Ilimitado" } },
        {
            nome: "Envio de Ração a Domicílio",
            lite: { qtd: 2, limite: "Cada, Limite 50KM" },
            premium: { qtd: 2, limite: "Cada, Limite 50KM" },
        },
        {
            nome: "Envio de Medicamentos a Domicílio",
            lite: { qtd: 2, limite: "Cada, Limite 50KM" },
            premium: { qtd: 2, limite: "Cada, Limite 50KM" },
        },
        { nome: "Informações de Serviçoes Veterinários Úteis", lite: { qtd: "Ilimitado" }, premium: { qtd: "Ilimitado" } },
        { nome: "Informações sobre Eventos e Exposição de Animais", lite: { qtd: "Ilimitado" }, premium: { qtd: "Ilimitado" } },        
        { nome: "Indicações de Hospitais 24h", premium: { qtd: "Ilimitado" } },
        { nome: "Informações Sobre Raças de Cães", premium: { qtd: "Ilimitado" } },
        { nome: "Indicações Sobre Vacinas", premium: { qtd: "Ilimitado" } },
        { nome: "Segunda Opinião Veterinária (por telefone)", premium: { qtd: "1" } },
    ];

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box mb={8}>
            <img src={banner} alt="banner" className={classes.img} />

            <Container maxWidth="lg">
                <Table className={classes.table} size={small ? "small" : "medium"}>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2} align="center" className={clsx(classes.servico)}>
                                <Typography variant="h6">Serviços</Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="center" className={clsx(classes.lite, classes.size)}>
                                <Typography variant="h6">Lite</Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="center" className={clsx(classes.premium, classes.size)}>
                                <Typography variant="h6">Premium</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.lite}>
                                Quantidade na vigência
                            </TableCell>
                            <TableCell align="center" className={classes.lite}>
                                Limite para utilização
                            </TableCell>
                            <TableCell align="center" className={classes.premium}>
                                Quantidade na vigência
                            </TableCell>
                            <TableCell align="center" className={classes.premium}>
                                Limite para utilização
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planosObj.map((p, idx) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    <Typography variant="body2">{p.nome}</Typography>
                                </TableCell>
                                <TableCell align="center">{p.lite ? p.lite.qtd ?? "-" : "X"}</TableCell>
                                <TableCell align="center">{p.lite ? p.lite.limite ?? "-" : "X"}</TableCell>
                                <TableCell align="center" className={classes.premium}>
                                    {p.premium && (p.premium.qtd ?? "-")}
                                </TableCell>
                                <TableCell align="center" className={classes.premium}>
                                    {p.premium && (p.premium.limite ?? "-")}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>
        </Box>
    );
}
