import CadastrarPetComGiftCard from "pages/CadastrarPetComGiftCard";
import ConfirmarResgate from "pages/ConfirmarResgate";
import GiftCard from "pages/GiftCard";
import ConfirmarEmail from "pages/login/ConfirmarEmail";
import InformacoesAdicionaisUsuario from "pages/login/InformacoesAdicionaisUsuario";
import MeusCodigos from "pages/MeusCodigos";
import Planos from "pages/Planos";
import ResgateGiftCardRealizado from "pages/ResgateGiftCardRealizado";
import TermosCondicoes from "./pages/TermosCondicoes";
import { createContext, useContext, useMemo, useState } from "react";
import { Route, Switch } from "react-router-dom";
import CadastrarUsuario from "./pages/login/CadastrarUsuario";
import ConfirmarEsqueciSenha from "./pages/login/ConfirmarEsqueciSenha";
import PaginaInicial from "./pages/pagina-inicial/PaginaInicial";
import ResgatarGiftCard from "./pages/ResgatarGiftCard";
import Sobre from "./pages/Sobre";
import TermosPrivacidade from "pages/TermoPrivacidade";

const ResgateGiftCardContext = createContext();

export const useResgateContext = () => {
    const context = useContext(ResgateGiftCardContext);

    return context;
};

function ResgateGiftCardProvider({ children }) {
    const [giftCard, setGiftCard] = useState();
    const [pet, setPet] = useState();

    const state = useMemo(() => {
        return { giftCard, setGiftCard, pet, setPet };
    }, [giftCard, setGiftCard, pet, setPet]);

    return <ResgateGiftCardContext.Provider value={state}>{children}</ResgateGiftCardContext.Provider>;
}

export const AppRouter = ({ setUsuario }) => {
    return (
        <div>
            <ResgateGiftCardProvider>
                <Switch>
                    <Route path="/cadastro-usuario">
                        <CadastrarUsuario />
                    </Route>
                    <Route path="/confirmar-esqueci-senha">
                        <ConfirmarEsqueciSenha />
                    </Route>
                    <Route path="/resgatar-gift-card">
                        <ResgatarGiftCard />
                    </Route>
                    <Route path="/informacoes-adicionais">
                        <InformacoesAdicionaisUsuario />
                    </Route>
                    <Route path="/cadastrar-pet">
                        <CadastrarPetComGiftCard />
                    </Route>
                    <Route path="/confirmar-resgate">
                        <ConfirmarResgate />
                    </Route>
                    <Route path="/resgate-gift-card-realizado">
                        <ResgateGiftCardRealizado />
                    </Route>
                    <Route path="/meus-codigos">
                        <MeusCodigos />
                    </Route>
                    <Route path="/confirmar-email">
                        <ConfirmarEmail />
                    </Route>
                    <Route path="/sobre">
                        <Sobre />
                    </Route>
                    <Route path="/planos">
                        <Planos />
                    </Route>
                    <Route path="/gift-card">
                        <GiftCard setUsuario={setUsuario} />
                    </Route>
                    <Route path="/termos-condicoes">
                        <TermosCondicoes />
                    </Route>
                    <Route path="/termos-privacidade">
                        <TermosPrivacidade />
                    </Route>
                    <Route path="/">
                        <PaginaInicial />
                    </Route>                    
                </Switch>
            </ResgateGiftCardProvider>
        </div>
    );
};
