import { Container, Link, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        maxWidth: "767px",
    },
    paragrafo: {
        textIndent: "30px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.875rem",
        fontFamily: "Gotham, Roboto, Helvetica, Arial, 'sans-serif'",
        textAlign: "justify",
    },
    titulo: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.875rem",
        fontFamily: "Gotham, Roboto, Helvetica, Arial, 'sans-serif'",
        fontWeight: "bold",
    },
    aceiteDiv: {
        backgroundColor: "white",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "10px",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dontBreakOut: {
        overflowWrap: "break-word",
        wordWrap: "break-word",
        msWordBreak: "break-all",
        wordBreak: "break-word",
        msHyphens: "auto",
        mozHyphens: "auto",
        webkitHyphens: "auto",
        hyphens: "auto",
    },
}));

function Paragrafo(props) {
    const classes = useStyles();
    return (
        <ListItem>
            <Typography align="justify" className={classes.paragrafo}>
                {props.children}
            </Typography>
        </ListItem>
    );
}

function Titulo(props) {
    const classes = useStyles();
    return (
        <ListItem>
            <Typography align="left" className={classes.titulo}>
                {props.children}
            </Typography>
        </ListItem>
    );
}

export default function TermosPrivacidade() {
    const referenceNode = useRef();
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h6" align="center">
                PRIVACIDADE E PROTEÇÃO DE DADOS
            </Typography>
            <br />
            <div>
                <List ref={referenceNode}>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> tem como compromisso resguardar a sua privacidade e proteger seus dados pessoais, e este
                        documento tem como objetivo mostrar como a <b>PROTEJA SEU PET</b> faz isso.
                    </Paragrafo>
                    <Paragrafo>
                        Importante ressaltar que a <b>PROTEJA SEU PET</b> trata os seus dados pessoais para prestar os serviços de forma
                        mais adequada e eficiente. Exatamente para que a <b>PROTEJA SEU PET</b> possa ter a confiança como base na prestação
                        dos serviços, tratamos quaisquer dados pessoais ou informações coletadas sobre você de forma confidencial e em
                        consonância com a legislação vigente de privacidade e proteção de dados.
                    </Paragrafo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> buscou redigir essa política de forma simples e acessível, para que você possa entender
                        como o tratamento de dados é realizado pela <b>PROTEJA SEU PET</b>. Caso você tenha qualquer dúvida sobre como o
                        tratamento de dados é realizado ou sobre esta Política de Privacidade, entre em contato com a <b>PROTEJA SEU PET</b>
                         através do endereço eletrônico: <i>suporte@petpupilo.com.br</i>
                    </Paragrafo>
                    <Titulo>
                        2. Como e por que a <b>PROTEJA SEU PET</b> coleta seus dados.
                    </Titulo>
                    <Paragrafo>
                        Para que a <b>PROTEJA SEU PET</b> possa prestar os serviços com qualidade, a <b>PROTEJA SEU PET</b> poderá tratar
                        diferentes tipos de dados pessoais.
                    </Paragrafo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> se compromete a tratar somente os dados pessoais que sejam necessários para a prestação dos
                        serviços ou quando a <b>PROTEJA SEU PET</b> entender que há um interesse seu em contratá-los e da 
                        <b>PROTEJA SEU PET</b> em prestá-lo.
                    </Paragrafo>
                    <Paragrafo>
                        Os dados podem ser coletados diretamente de você através do website ou tele atendimento da <b>PROTEJA SEU PET</b>
                        , indiretamente através do seu dispositivo eletrônico.
                    </Paragrafo>
                    <Paragrafo>
                        E para que a <b>PROTEJA SEU PET</b> possa ser transparente no tratamento de dados pessoais descrevemos abaixo as
                        finalidades pelo qual podemos tratar os seus dados:
                    </Paragrafo>
                    <ul className={classes.paragrafo}>
                        <li>
                            Contratar os serviços da <b>PROTEJA SEU PET</b>:
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> trata os seus dados pessoais para que possa efetivar o pagamento e as condições
                                    dos serviços, bem como para finalizar a contratação desses serviços; A <b>PROTEJA SEU PET</b> também faz
                                    o tratamento dos dados sobre aquelas contratações que você iniciou, mas não finalizou pelo
                                    nosso website, fazemos esse tratamento, porque entendemos que você possui interesse nessa contratação,
                                    mas você sempre poderá se opor a esse tratamento, caso entenda que não atende a suas expectativas.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Prevenção e combates a fraudes:
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> faz o tratamento de alguns dos seus dados pessoais para fornecer segurança para
                                    o website e na prestação dos nossos serviços para evitar fraudes e manter a integridade dos serviços que
                                    são usados e oferecidos para você;
                                </li>
                            </ul>
                        </li>
                        <li>
                            Oferecer novos serviços e comunicar com você:
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> faz o tratamento dos seus dados pessoais para que possa lhe ofertar novos
                                    serviços quando você já é cliente ou quando iniciou uma contratação no website, mas não finalizou.
                                    Fazemos isso porque entendemos que você possui interesse nos nossos serviços, mas esclarecemos que você
                                    sempre poderá se descadastrar do envio das ofertas dos nossos serviços, através de um link de fácil
                                    acesso contido naquela comunicação quando você entender que esses serviços não atendem a sua
                                    expectativa. Também pode ocorrer o tratamento dos seus dados para lhe comunicar sobre o andamento de
                                    algum atendimento que você possa ter solicitado para nós.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Avaliar o desempenho dos nossos serviços, pesquisa e inovação:
                            <ul>
                                <li>
                                    Para que a <b>PROTEJA SEU PET</b> possa melhorar os serviços oferecidos, pedimos informações sobre a
                                    avaliação do desempenho dos serviços que foram prestados; <b>PROTEJA SEU PET</b> também poderá usar seus
                                    alguns dados para pesquisa estática para a inovação dos serviços e na elaboração de novos produtos;
                                </li>
                            </ul>
                        </li>
                        <li>
                            Executar nossos serviços:
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> também poderá fazer o tratamento dos seus dados para que possa executar os
                                    serviços, pois sem eles não é possível te identificar e executar o serviço pelo que você fez a
                                    contratação. Nessa hipótese, a PROTEJA SEU PET também poderá compartilhar seus dados com os prestadores
                                    de serviços, assim quando você solicita um prestador de serviço para lhe dar assistência prevista na sua
                                    apólice de seguro, a <b>PROTEJA SEU PET</b> compartilha seus dados com este prestador ou empresa que irá
                                    até o local indicado para prestar o serviço em nome da <b>PROTEJA SEU PET</b>.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Redes sociais:
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> também faz o tratamento dos dados quando você interage com funções das redes
                                    sociais para anunciar nossos produtos que sejam interessantes para você. Você poderá se opor a esses
                                    anúncios através da configuração de privacidade dessa rede social que você utiliza.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Informações de dispositivos:
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> poderá tratar seus dados que são coletados automaticamente através dos seus
                                    dispositivos móveis ou computador que estão conectados pela internet, através dos cookies, que você
                                    poderá verificar pela seção “10”.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <Titulo>3. Quem faz o tratamento dos seus dados pessoais?</Titulo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> juntamente com suas empresas parceiras (<b>“MAPFRE” e “SUL AMÉRICA</b>), que é a
                        responsável pelo tratamento dos seus dados pessoais.
                    </Paragrafo>
                    <Paragrafo>
                        No item “7”, você poderá verificar todos os direitos que poderá exercer perante a <b>PROTEJA SEU PET</b> ou
                        diretamente pela Autoridade Nacional de Proteção de Dados (“ANPD”).
                    </Paragrafo>
                    <Titulo>
                        4. Tratamento de dados sensíveis de crianças e adolescentes pela <b>PROTEJA SEU PET</b>
                    </Titulo>
                    <Paragrafo>
                        Em alguns casos, a <b>PROTEJA SEU PET</b> poderá realizar a coleta de alguns dados pessoais considerados como
                        sensíveis conforme definição na Lei Geral de Proteção de Dados<sub>1</sub>  
                        <b>
                            (“LGPD”) (dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a
                            sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida
                            sexual, dado genético ou biométrico)
                        </b>
                         ou a coleta de algum dado pessoal que possa vir a revelar algum dado sensível. Nesses casos, apenas iremos realizar
                        o tratamento desses dados de acordo com as hipóteses legais previstas na LGPD.
                    </Paragrafo>
                    <Paragrafo>
                        Além disso, durante a prestação dos serviços da <b>PROTEJA SEU PET</b> poderá ser coletado dados de menores de 18
                        anos. Nesses casos, a <b>PROTEJA SEU PET</b> sempre buscará a autorização de seu representante legal ou de um de
                        seus pais, conforme regra presente na LGPD.
                    </Paragrafo>
                    <Paragrafo>
                        <sub>1</sub> Lei nº 13.709 de 2018
                    </Paragrafo>
                    <Titulo>
                        5. A <b>PROTEJA SEU PET</b> compartilha meus dados com alguém?
                    </Titulo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> compartilha ou realiza transferência de seus dados pessoais nas seguintes hipóteses:
                    </Paragrafo>
                    <ul className={classes.paragrafo}>
                        <li>
                            Prestadores de Serviços
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> contrata prestadores de serviços que auxiliam na prestação dos serviços para
                                    você, por exemplo quando você contrata os serviços de seguros compartilhamos seus dados com o prestador
                                    escolhido da rede credenciada para que você possa ser atendido. Outra possibilidade é o compartilhamento
                                    dos dados para suporte, SAC, detecção de fraudes, análise de pesquisa de mercado, cobranças, etc. As
                                    informações compartilhadas com os prestadores de serviços são estritamente necessárias para a execução
                                    dos seus serviços e eles são obrigados a não as divulgar ou utilizá-las para outros fins.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Obrigação Legal ou Regulatória
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> poderá ser compelida a divulgar ou compartilhar seus dados pessoais, caso seja
                                    exigido por intimação judicial e/ou em função de alguma legislação. A <b>PROTEJA SEU PET</b> também
                                    poderá revelar seus dados pessoais de boa fé nos casos que sejam necessários para investigação ou
                                    combate à fraude.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <Titulo> 6. Quais são seus direitos conforme a LGPD? </Titulo>
                    <Paragrafo>
                        Você sempre poderá exercer seus direitos relativos a privacidade e a proteção dos seus dados e a 
                        <b>PROTEJA SEU PET</b>, além de se preocupar com a segurança desses dados, também se preocupa que você tenha acesso
                        e conhecimento de todos os seus direitos. Dessa forma, segue abaixo o resumo de todos os direitos contidos no artigo
                        18 da Lei 13.709/18 com uma breve explicação de cada:
                    </Paragrafo>
                    <ul className={classes.paragrafo}>
                        <li>
                            <b>Acesso, confirmação da existência de tratamento e informação sobre o compartilhamento.</b>
                            <ul>
                                <li>
                                    Este direito permite que o você possa requisitar quais dados pessoais que a <b>PROTEJA SEU PET</b>
                                     possui sobre você, bem como requerer a confirmação do tratamento que a <b>PROTEJA SEU PET</b> realiza
                                    dos seus dados pessoais. Você também tem o direito de receber informações sobre eventuais entidades
                                    públicas e privadas com as quais a <b>PROTEJA SEU PET</b> realiza o uso compartilhado de dados, se
                                    houver.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Correção de dados.</b>
                            <ul>
                                <li>
                                    Este direito permite que você possa solicitar a correção dos seus dados pessoais, caso identifique
                                    qualquer erro, incorreção, que estes estejam incompletos ou desatualizados. Contudo, para ser efetivada
                                    essa correção, pode ser necessário checar a veracidade dos dados que você fornece para a
                                    <b>PROTEJA SEU PET</b>.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Solicitar anonimização, bloqueio ou eliminação de dados.</b>
                            <ul>
                                <li>
                                    o Este direito permite que você solicite para a <b>PROTEJA SEU PET</b> anonimizar, bloquear ou eliminar
                                    os seus dados pessoais nos seguintes cenários: (a) dados pessoais desnecessários; (b) dados pessoais
                                    excessivos; ou (c) dados pessoais tratados em desconformidade com a LGPD. Todos os dados coletados serão
                                    anonimizados ou excluídos dos servidores da <b>PROTEJA SEU PET</b> quando você requisitar (desde que
                                    seja possível) ou quando não forem mais necessários para a <b>PROTEJA SEU PET</b>, salvo se houver outra
                                    razão para a sua manutenção, como eventual obrigação legal de retenção dos dados pessoais ou caso haja
                                    necessidade de preservação para resguardar seus e os direitos da PROTEJA SEU PET.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Portabilidade de dados.</b>
                            <ul>
                                <li>
                                    A <b>PROTEJA SEU PET</b> fornecerá para você ou para terceiros que você escolher, mediante requisição
                                    expressa, seus dados pessoais, conforme a capacidade técnica disponível.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Oposição ao tratamento</b>
                            <ul>
                                <li>
                                    Você também tem o direito de contestar as atividades de tratamento de dados realizadas pela 
                                    <b>PROTEJA SEU PET</b>. Em determinadas situações, a PROTEJA SEU PET poderá demonstrar que possui
                                    motivos legítimos para continuar a tratar seus dados, os quais se sobrepõem ao direito de oposição,
                                    como, por exemplo, nas atividades requeridas por legislação.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Retirar o seu consentimento e saber as consequências de não fornecer consentimento.</b>
                            <ul>
                                <li>
                                    Você tem o direito de não fornecer consentimento, nas hipóteses em que são solicitados, ocasião em que
                                    será informado sobre as consequências da negativa. Também tem direito de retirar o seu consentimento, a
                                    qualquer momento, no entanto, isso não afetará a legalidade de qualquer tratamento de dados realizado
                                    anteriormente com base nesse consentimento. Se você retirar o seu consentimento, talvez a 
                                    <b>PROTEJA SEU PET</b> não poderá fornecer determinados serviços. E se for este o caso, a 
                                    <b>PROTEJA SEU PET</b> irá te avisar no momento em que isso ocorrer.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Revisão de decisão automatizada.</b>
                            <ul>
                                <li>
                                    Você também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento
                                    automatizado de seus dados pessoais que afetem seus interesses.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> poderá solicitar informações específicas sobre você para fins de confirmação da sua
                        identidade e para a sua própria proteção. A <b>PROTEJA SEU PET</b> se reserva o direito de rejeitar requerimentos no
                        caso destes serem ilegítimos, ilegais e observando a proteção dos segredos comerciais e industriais, conforme
                        permitido na legislação vigente.
                    </Paragrafo>
                    <Titulo>7. Por quanto tempo iremos reter seus dados?</Titulo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> manterá seus dados pessoais somente pelo tempo que for necessário para cumprir com as
                        finalidades para as quais são tratadas, inclusive para fins de cumprimento de quaisquer obrigações legais,
                        contratuais, de prestação de contas ou requisição de autoridades competentes.
                    </Paragrafo>
                    <Paragrafo>
                        Para determinar o período de retenção adequado para os dados pessoais, consideramos a quantidade, a natureza e a
                        sensibilidade dos dados pessoais, o risco potencial de possíveis incidentes de segurança que possam ocorrer, a
                        finalidade de processamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros meios,
                        bem como os requisitos legais aplicáveis.
                    </Paragrafo>
                    <Titulo>9. Como funciona Segurança da Informação?</Titulo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> implementa medidas técnicas e organizacionais de segurança para proteger sua privacidade e
                        seus dados pessoais. A <b>PROTEJA SEU PET</b> também utiliza práticas de mercado para garantir a segurança da
                        informação.
                    </Paragrafo>
                    <Titulo>10. Cookies</Titulo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> utiliza cookies para lhe proporcionar uma melhor experiência de navegação que atenda às
                        suas necessidades, bem como para proteger e melhorar a plataforma do site da <b>PROTEJA SEU PET</b>. Leia
                        atentamente esta política de cookies para saber mais sobre o uso responsável que a <b>PROTEJA SEU PET</b> faz deles
                        e sobre as opções que você pode configurar no seu navegador para gerenciá-los.
                    </Paragrafo>
                    <Paragrafo>
                        <u>O que são cookies?</u>
                    </Paragrafo>
                    <Paragrafo>
                        Os cookies são pequenos arquivos de dados que são gerados no seu computador e permite conhecer sua frequência de
                        visitas, o conteúdo mais selecionado e os elementos de segurança que podem intervir no controle de acesso a áreas
                        restritas, bem como na exibição de publicidade de acordo com critérios predefinidos pela <b>PROTEJA SEU PET</b> e
                        ativados por cookies servidos por essa empresa ou por terceiros que prestam esses serviços em nome da 
                        <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>Como regra geral, existem os seguintes tipos de cookies:</Paragrafo>
                    <ul className={classes.paragrafo}>
                        <li>
                            Dependendo de quando expiram:
                            <ol>
                                <li>Cookies de sessão: expiram no final de uma sessão;</li>
                                <li>Cookies persistentes: não expiram no final de uma sessão.</li>
                            </ol>
                        </li>
                        <li>
                            Dependendo de sua origem:
                            <ol>
                                <li>Origem: habilitado pelo site que está sendo navegado;</li>
                                <li>Cookies de terceiros: cookies de outros websites.</li>
                            </ol>
                        </li>
                        <li>
                            Dependendo da sua finalidade:
                            <ol>
                                <li>Técnico: permitem a navegação e utilização de diferentes serviços;</li>
                                <li>Personalização: permitem o acesso a serviços com recursos predefinidos;</li>
                                <li>Análise: permitem o monitoramento e análise do seu comportamento</li>
                                <li>Publicidade: permitem a gestão de espaços publicitários;</li>
                                <li>
                                    Publicidade comportamental: permitem a gestão de espaços publicitários. Armazenam informações sobre o
                                    comportamento do cliente.
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <Paragrafo>
                        <u>Que tipos de cookies utilizamos e porquê?</u>
                    </Paragrafo>
                    <Paragrafo>Os seguintes tipos de cookies são usados neste site, para os seguintes fins:</Paragrafo>
                    <ol className={classes.paragrafo}>
                        <li>
                            <Paragrafo>
                                <i>Cookies analíticos</i>
                            </Paragrafo>
                            <Paragrafo>
                                Esses cookies são usados para coletar estatísticas sobre a atividade do website. As estatísticas coletadas
                                incluem o número de usuários que visitaram o site e o número de páginas visitadas, além da sua atividade
                                no website e sua frequência de uso. As informações coletadas são sempre anônimas, de modo que
                                nenhum link pode ser desenhado entre os dados e o indivíduo ao qual eles se referem.
                            </Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Cookies de autenticação</i>
                            </Paragrafo>
                            <Paragrafo>
                                Esses cookies são usados para manter suas sessões durante cada visita. Eles predominam em áreas privadas
                                do website que exigem seu nome e a senha para acesso.
                            </Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Cookies utilizados pelas redes sociais</i>
                            </Paragrafo>
                            <Paragrafo>
                                Esses cookies possibilitam que você compartilhe conteúdo de seu interesse com seus contatos em uma rede
                                social, pressionando o botão correspondente (plug- in) inserido no site.
                            </Paragrafo>
                            <Paragrafo>
                                Esses plug-ins armazenam e acessam os cookies no seu terminal e permite que a rede social te identifique
                                quando você interage com os plug-ins.
                            </Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Cookies usados por complementos de conteúdo externo</i>
                            </Paragrafo>
                            <Paragrafo>
                                Esses cookies são necessários para fornecer determinados tipos de serviços e são armazenados por terceiros.
                                Esta categoria de cookie inclui, por exemplo, cookies de leitor multimídia que são utilizados para armazenar
                                dados técnicos para reproduzir conteúdo de vídeo ou áudio, como a qualidade da imagem, as definições de
                                carregamento, etc.
                            </Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Cookies de terceiros para personalizar espaços publicitários</i>
                            </Paragrafo>
                            <Paragrafo>
                                Esses cookies possibilitam a gestão de spots publicitários que você vê quando acessa o website. Eles são
                                armazenados por terceiros.
                            </Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Quem acessa as informações dos cookies?</i>
                            </Paragrafo>
                            <Paragrafo>As informações armazenadas nos cookies são usadas exclusivamente pela PROTEJA SEU PET.</Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Os cookies podem ser desativados?</i>
                            </Paragrafo>
                            <Paragrafo>
                                Exceto para os cookies analíticos, o uso de cookies afeta sua privacidade, pois, como regra geral, permite
                                relacionar seu conteúdo com os endereços IP de conexão dos clientes e outros dados pessoais, como aqueles
                                fornecidos voluntariamente.
                            </Paragrafo>
                            <Titulo>
                                Ao aceitar esta política de privacidade e cookies navegando no website da PROTEJA SEU PET, você consente com
                                a coleta de cookies para os propósitos mencionados. Isto ocorre apenas no ambiente online em decorrência da
                                legislação vigente na data da publicação desta política.
                            </Titulo>
                            <Paragrafo>
                                Não obstante o acima exposto, se você decidir eliminar todos os cookies armazenados em seu computador que
                                exigem necessariamente seu consentimento, podem fazê-lo usando as ferramentas que seus navegadores oferecem
                                para tais finalidades. A este respeito e dependendo do navegador, os links a seguir fornecem os
                                procedimentos para desabilitar os cookies:
                            </Paragrafo>
                            <ul className={classes.paragrafo}>
                                <li>
                                    Firefox: 
                                    <Link
                                        className={classes.dontBreakOut}
                                        href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
                                    </Link>
                                </li>
                                <li>
                                    Internet Explorer: 
                                    <Link
                                        className={classes.dontBreakOut}
                                        href="https://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies
                                    </Link>
                                </li>
                                <li>
                                    Chrome: 
                                    <Link
                                        className={classes.dontBreakOut}
                                        href="https://support.google.com/chrome/bin/answer.py?hl=pt&answer=95647"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://support.google.com/chrome/bin/answer.py?hl=pt&amp;answer=95647
                                    </Link>
                                </li>
                                <li>
                                    Safari: 
                                    <Link
                                        className={classes.dontBreakOut}
                                        href="https://support.apple.com/pt-br/HT201265"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://support.apple.com/pt-br/HT201265
                                    </Link>
                                </li>
                                <li>
                                    Ópera: 
                                    <Link
                                        className={classes.dontBreakOut}
                                        href="https://help.opera.com/Windows/8.54/es-ES/cookies.html#privacy-cookie"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://help.opera.com/Windows/8.54/es-ES/cookies.html#privacy-cookie
                                    </Link>
                                </li>
                            </ul>
                            <Paragrafo>
                                Se você não consentir com os cookies ou quiser desabilitá-los posteriormente poderá ter as funcionalidades
                                do website prejudicadas.
                            </Paragrafo>
                        </li>
                        <li>
                            <Paragrafo>
                                <i>Alterações e atualizações da Política de Cookies</i>
                            </Paragrafo>
                            <Paragrafo>
                                A <b>PROTEJA SEU PET</b> pode modificar esta política de cookies de acordo com os requisitos legislativos ou
                                regulamentares, ou afim de adaptar a presente política às instruções e recomendações estabelecidas pelos
                                reguladores. Recomendamos que você reveja periodicamente essa política de cookies.
                            </Paragrafo>
                        </li>
                    </ol>
                    <Titulo>11. Mudanças na Política de Privacidade.</Titulo>
                    <Paragrafo>
                        Esta Política de Privacidade pode passar por atualizações para que a PROTEJA SEU PET possa estar cada vez mais
                        adequada com as melhores práticas de Privacidade e Proteção de dados. Desta forma, a <b>PROTEJA SEU PET</b>
                         recomenda que você visite periodicamente esta página para tomar conhecimento sobre as suas modificações. Caso sejam
                        feitas alterações relevantes no presente documento, iremos publicar essa atualização, indicando a data da última
                        atualização, e garantindo que o você tenha acesso a nova versão e possa estar ciente dessas alterações.
                    </Paragrafo>
                </List>
            </div>
        </Container>
    );
}
