import { Box, Button, Container, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUsuarioLogado } from "App";
import raspadinha from "assets/RASPADINHA.png";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { LoginForm } from "./login/Login";
import ResgatarGiftCard from "./ResgatarGiftCard";

const useStyles = makeStyles((theme) => ({
    img: {
        width: "100%",
    },
    box: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    list: {
        "& li": {
            paddingTop: theme.spacing(0.2),
            paddingBottom: theme.spacing(0.2),
        },
    },
}));
export default function GiftCard({ setUsuario }) {
    const classes = useStyles();
    const usuario = useUsuarioLogado();
    const history = useHistory();

    useEffect(() => {
        if (usuario && !usuario.aceitouTermos) {
            history.push("termos-condicoes");
        }
    }, [usuario, history]);

    return (
        <>
            <Container>
                <Grid container spacing={4} className={classes.box}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" align="center">
                            Modo de uso
                        </Typography>
                        <List disablePadding className={classes.list}>
                            <ListItem>
                                <ListItemText disableTypography>
                                    <Typography variant="body2">Raspe para descobrir o seu código</Typography>
                                    <Box height="30px">
                                        <img src={raspadinha} alt="raspadinha" className={classes.im3g} />
                                    </Box>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Acesse o site (www.protejaseupet.com.br)"></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Vá na aba gift card e digite o código da raspadinha"></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Preencha seu cadastro e receba seu certificado por e-mail"></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ResgatarGiftCard />
                    </Grid>
                    {!usuario && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h4" color="textPrimary" gutterBottom align="center">
                                    Já sou cliente
                                </Typography>
                                <LoginForm setUsuario={setUsuario} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h4" color="textPrimary" gutterBottom align="center">
                                    Ainda não tem cadastro ?
                                </Typography>
                                <Box display="flex" justifyContent="center" mt={4}>
                                    <Button component={Link} to="cadastro-usuario" variant="contained">
                                        Clique aqui e faça parte da Proteja seu Pet
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
        </>
    );
}
