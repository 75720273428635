import {
    Avatar,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PetsIcon from "@material-ui/icons/Pets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useResgateContext } from "App.routes";
import TextFieldDefault from "components/TextFieldDefault/TextFieldDefault";
import DataTextField from "components/TextMaskCustom/DataTextField";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "services/axios-utils";
import * as Yup from "yup";
import { CadastroStepper } from "./ResgatarGiftCard";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        marginTop: theme.spacing(3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

const validationSchema = Yup.object({
    nome: Yup.string().max(80).required(),
    sexo: Yup.string().required(),
    raca: Yup.object().nullable().required(),
    nascimento: Yup.string().required(),
});

export default function CadastrarPetComGiftCard() {
    const classes = useStyles();
    const history = useHistory();
    const [especie, setEspecie] = useState("");
    const [racas, setRacas] = useState([]);
    const context = useResgateContext();
    const [initialValues, setInitialValues] = useState({
        nome: "",
        sexo: "",
        raca: null,
        nascimento: null,
    });

    useEffect(() => {
        if (context.pet) {
            buscarRacas(context.pet.raca.especie);
            setEspecie(context.pet.raca.especie);
            setInitialValues(context.pet);
        }
    }, [context.pet]);

    const handleChangeEspecie = (event, formik) => {
        const valor = event.target.value;

        setEspecie(valor);
        setRacas([]);
        formik.setFieldValue("raca", null);

        buscarRacas(valor);
    };

    function buscarRacas(valor) {
        axios.get(`raca?especie=${valor}`).then((r) => setRacas(r.data));
    }

    const onSubmit = (values) => {
        context.setPet(values);
        history.push("confirmar-resgate");
    };

    return (
        <Container component="main" maxWidth="md">
            <CadastroStepper activeStep={2} />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <PetsIcon />
                </Avatar>
                <Typography variant="h4">Cadastro do Pet</Typography>
                <Typography variant="h5" gutterBottom color="textSecondary">
                    Agora precisamos dos dados do pet que será coberto pelo plano
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    enableReinitialize
                >
                    {(formik) => (
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextFieldDefault label="Nome" name="nome" autoFocus />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Espécie</FormLabel>
                                        <RadioGroup row value={especie} onChange={(e) => handleChangeEspecie(e, formik)}>
                                            <FormControlLabel value="CACHORRO" control={<Radio />} label="Cachorro" />
                                            <FormControlLabel value="GATO" control={<Radio />} label="Gato" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl component="fieldset" error={formik.touched.sexo && Boolean(formik.errors.sexo)}>
                                        <FormLabel component="legend">Sexo</FormLabel>
                                        <RadioGroup
                                            name="sexo"
                                            row
                                            value={formik.values.sexo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <FormControlLabel value="F" control={<Radio />} label="Femea" />
                                            <FormControlLabel value="M" control={<Radio />} label="Macho" />
                                        </RadioGroup>
                                        {formik.touched.sexo && formik.errors.sexo && <FormHelperText>{formik.errors.sexo}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        name="raca"
                                        options={racas}
                                        disabled={racas.length === 0}
                                        getOptionLabel={(o) => o.nome}
                                        getOptionSelected={(o) => o.id === formik.values.raca.id}
                                        value={formik.values.raca}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue("raca", newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Raça"
                                                name="raca"
                                                variant="outlined"
                                                error={formik.touched.raca && Boolean(formik.errors.raca)}
                                                helperText={formik.touched.raca && formik.errors.raca && formik.errors.raca}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DataTextField label="Data de Nascimento" name="nascimento" />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container justify="space-between" spacing={2} alignItems="center">
                                <Grid item>
                                    <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                                        Continuar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>
        </Container>
    );
}
