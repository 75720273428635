import {
    Container,
    Typography,
    ListItem,
    ListItemAvatar,    
    ListItemText,
    List,
    Button,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React from "react";

export default function ComoFunciona() {
    const [panelAberto, setPanelAberto] = React.useState(null);

    const handleChange = (panel) => (event) => {
        setPanelAberto(panelAberto !== panel ? panel : null);
    };

    const PaginaInicialListItem = ({ children, titulo, nome }) => {        
        const icone = panelAberto === nome ? <Remove /> : <Add />;
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <img width="50px" alt="" src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/pata2.png" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Button variant="text" startIcon={icone} onClick={handleChange(nome)}>
                                <Typography variant="h6">{titulo}</Typography>
                            </Button>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography variant="body1">{panelAberto === nome ? children : ""}</Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    };

    return (
        <Container maxWidth="lg">
            <img
                alt=""
                style={{ width: "100%" }}
                src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/comoFunciona.png"
            />
            <br />
            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
            <PaginaInicialListItem nome="panel1" titulo="Como funciona?">
                <ul>
                    <li>
                        É só ir a um comércio pet mais próximo que terá o nosso Gift Card, após a compra, raspe no local indicado e insira o
                        código em nosso site.
                    </li>
                    <li>Após os 5 dias de carência, todas as coberturas da Proteja seu Pet poderão ser usadas, conforme condições.</li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel2" titulo="E como funciona a carência?">
                <ul>
                    <li>
                        A carência se iniciará com a partir do momento que o código contido no Gift é inserido em nosso site. Nesse momento,
                        começará a contar o prazo de 05 (cinco) dias úteis para a utilização de todas as coberturas contido no plano
                        contratado, conforme condições gerais.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel3" titulo="Terá taxas ou coparticipação para utilização?">
                <ul>
                    <li>Não. Você pagará apenas o Gift Card e poderá utilizar nossas coberturas sem precisar pagar nada mais por isso.</li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel4" titulo="Há limite de idade para a contratação?">
                <ul>
                    <li>Não. Não há limite de idade na contratação e nem na utilização de nossos planos.</li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel5" titulo="Receberei algum documento após a contratação?">
                <ul>
                    <li>
                        Sim. Receberá as condições gerais com todo o descritivo e informações da sua contratação, os termos de condições e
                        política de privacidade demonstrando o compromisso da empresa com nosso cliente. Além disso, irá receber informações
                        sobre a abertura em caso de atendimento.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel6" titulo="Como funciona o acionamento das coberturas?">
                <ul>
                    <li>
                        Para utilizar umas das coberturas, é só ligar no 0800 que será informado no e-mail de boas- vindas, identificar-se e
                        ira acionar a cobertura, assim o atendente irá lhe encaminhar ao conveniado mais próximo.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem
                nome="panel7"
                titulo="
                Como sei os horários de funcionamento, endereço e o que as clínicas credenciadas realizam pelo Plano?
                "
            >
                <ul>
                    <li>
                        Ao ligar para a central de atendimento, estes terão todas as informações necessárias para sua maior comodidade e
                        usabilidade
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem
                nome="panel8"
                titulo="Como procedo se as clinicas credenciadas estiverem fora do horário de atendimento?"
            >
                <ul>
                    <li>
                        No caso de estar fora do horário de atendimento, será viabilizado o reembolso para realizar serviço com um
                        profissional que atenda naquela necessidade.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel9" titulo="Meus procedimentos são limitados?">
                <ul>
                    <li>
                        Depende da cobertura. Na tabela de coberturas estará descrita quantidades de valores cobertos por cada cobertura
                        naquele ano de vigência.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel10" titulo="Quanto tempo de vigência tenho no meu plano?">
                <ul>
                    <li>Terá 12 meses de vigência depois de adicionar o código contigo no Gift Card em nosso site.</li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel11" titulo="Como renovo minha assinatura?">
                <ul>
                    <li>
                        Poderá encaminhar-se ao mesmo ponto de venda em que adquiriu seu Gift Card atual ou algum outro ponto que seja
                        parceiro da Proteja seu Pet.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel12" titulo="Como faço para trocar de Plano? O que acontece com a carência dos procedimentos?">
                <ul>
                    <li>
                        Para fazer o upgrade do seu plano, procure um ponto de vendas parceiro e adquira o nosso plano Premium ou nos chame
                        no WhatsApp para que possamos fazer a migração do seu plano. Começará a contar como um novo plano, ou seja, terá os
                        5 dias de carência e o seu tempo de vigência será de 12 meses a contar da migração.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel13" titulo="Posso ter mais de um plano contratado para o meu CPF?">
                <ul>
                    <li>Sim. Você pode adquirir a quantidade de planos relativo a quantidades de pet’s que você tenha.</li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel14" titulo="O que não está coberto pelos Planos?">
                <ul style={{ listStyleType: "upper-latin" }}>
                    <li>
                        Ataques indevidos do animal assistido a terceiros, assim como Por qualquer despesa incorrida por eles junto a
                        estabelecimentos Que os estiverem atendendo;
                    </li>
                    <li>Serviços solicitados diretamente pelo usuário, sem prévio Consentimento do canal de atendimento de assistência;</li>
                    <li>
                        Despesas correspondentes a assistências veterinárias Despendidas pelo usuário antes de seu contato. Assistência;
                    </li>                    
                    <li>
                        Assistência a toda e qualquer consequência resultante de Morte ou lesões causadas, direta ou indiretamente, por
                        atividades Criminosas ou dolosas;
                    </li>
                    <li>
                        Assistência derivada da morte por sacrifício induzido e/ou Lesões e consequências decorrentes de tentativas do
                        mesmo;
                    </li>
                    <li>
                        Assistência por doenças ou estados patológicos produzidos Por consumo induzido de drogas, produtos tóxicos,
                        narcóticos ou Medicamentos proibidos e ou adquiridos sem recomendação Veterinária;
                    </li>
                    <li>
                        Despesas extras da estadia, como por exemplo refeições, bem Como todas aquelas que não estejam inclusas no custo da
                        diária Do hotel;
                    </li>
                    <li>Descontos sobre produtos e/ou serviços de empresas não Credenciadas; </li>
                    <li>Descontos sobre produtos e/ou serviços não indicados pelos Parceiros. </li>
                    <li>Doenças (inclusive as doenças preexistentes).</li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem
                nome="panel15"
                titulo="Os preços variam conforme a idade dos meus pets? A mensalidade sofre algum reajuste anual?"
            >
                <ul>
                    <li>
                        Não, o preço é o mesmo na contratação não importando a raça ou porte do pet. E a mensalidade ira manter-se a mesma
                        até o final da sua vigência atual.
                    </li>
                </ul>
            </PaginaInicialListItem>

            <PaginaInicialListItem nome="panel16" titulo="Precisa ser eu para levar meu pet para consultar ou pode ser outra pessoa?">
                <ul>
                    <li>Sim, porem deverá ser avisado na abertura do atendimento os dados da pessoa que irá leva-lo até a credenciada.</li>
                </ul>
            </PaginaInicialListItem>
            <br/>
            </List>
        </Container>
    );
}
