import { Avatar, Button, CircularProgress, Container, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useQuery } from "hooks/useQuery";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "services/user-service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ConfirmarEmail() {
    const [codigo, setCodigo] = useState();
    const [loading, setLoading] = useState();
    const history = useHistory();
    const email = useQuery().get("email");

    const confirmar = (event) => {
        event.preventDefault();

        const user = new User(email);

        setLoading(true);
        user.confirmRegistration(codigo)
            .then((res) => {
                toast.success("E-mail confirmado com sucesso. Por favor, realize seu login.");
                history.push("/gift-card");
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Ocorreu um erro ao confirmar.");
                throw err;
            });
    };

    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5" gutterBottom>
                    Confirmar E-mail
                </Typography>
                <div>
                    <Typography variant="subtitle1" color="textSecondary">
                        Um e-mail foi enviado com o código de ativação da sua conta.
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Digite o código abaixo para ter acesso ao sistema.
                    </Typography>
                </div>
                <form onSubmit={confirmar}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="codigo"
                        label="Código"
                        name="codigo"
                        autoComplete="codigo"
                        autoFocus
                        onChange={(ev) => setCodigo(ev.target.value)}
                    />
                    <Button type="submit" disabled={loading} fullWidth variant="contained" color="primary" className={classes.submit}>
                        Confirmar E-mail
                    </Button>
                    {loading && <CircularProgress size={24} />}
                </form>
            </div>
        </Container>
    );
}
