import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBRLocale from "date-fns/locale/pt-BR";
import { useField } from "formik";
import React from "react";

export default function DataTextField({ helperText, fullWidth = true, label, ...props }) {
    const [field, meta, helpers] = useField(props);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
            <KeyboardDatePicker
                autoOk
                format="dd/MM/yyyy"
                error={meta.touched && Boolean(meta.error)}
                id={props.id}
                name={props.name}
                label={label}
                inputVariant="outlined"
                helperText={helperText || (meta.touched && meta.error && meta.error)}
                onError={(error) => {
                    // handle as a side effect
                    if (error !== meta.error) {
                        helpers.setError(error);
                    }
                }}
                {...field}
                {...props}
                onChange={(date) => helpers.setValue(date, false)}
            />
        </MuiPickersUtilsProvider>
    );
}
