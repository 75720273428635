import { Avatar, Button, CircularProgress, Container, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Formik } from "formik";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "services/user-service";
import * as Yup from "yup";
import TextFieldDefault from "../../components/TextFieldDefault/TextFieldDefault";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        marginTop: theme.spacing(3),
    },
    loading: {
        height: "100%",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: 26,
    },
}));

const validationSchema = Yup.object({
    nome: Yup.string().max(80).required(),
    email: Yup.string().email().required(),
    senha: Yup.string()
        .min(6)
        .required(),
    confirmaSenha: Yup.string()
        .oneOf([Yup.ref("senha"), null], "Senha não é igual.")
        .required(),
});

const initialValues = {
    nome: "",
    email: "",
    senha: "",
    confirmaSenha: "",
};

export default function CadastrarUsuario({ setUser }) {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const onSubmit = (values) => {
        const userService = new User(values.email);

        setLoading(true);
        userService
            .signUp(values.nome, values.email, values.senha)
            .then((returnUser) => {
                history.push("/confirmar-email?email=" + encodeURIComponent(values.email));
            })
            .catch((err) => {
                setLoading(false);
                if (err.code === "UsernameExistsException") {
                    toast.error("Esse e-mail já está cadastrado");
                } else {
                    toast.error("Ocorreu um erro ao criar usuário. " + err.message);
                }
                throw err;
            });
    };

    return (
        <Container component="main" maxWidth="sm">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h4">Cadastro de Usuário</Typography>
                <Typography variant="body2" color="textSecondary">
                    Todos os campos são obrigatórios
                </Typography>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur={false}>
                    {(formik) => (
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextFieldDefault label="Nome Completo" name="nome" autoFocus />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldDefault label="E-mail" name="email" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextFieldDefault label="Senha" name="senha" type="password" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextFieldDefault label="Confirme a senha" name="confirmaSenha" type="password" />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={2} justify="space-between" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Button type="submit" disabled={loading} variant="contained" color="primary">
                                        Cadastrar
                                    </Button>
                                    {loading && (
                                        <span className={classes.loading}>
                                            <CircularProgress size={26} />
                                        </span>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Link component={RouterLink} to="gift-card" variant="body2">
                                        Já tem uma conta?
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>
        </Container>
    );
}
