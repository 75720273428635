import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import axios from "services/axios-utils";

export class User {
    _userName;
    _pool;
    _user;

    constructor(userName) {
        this.userName = userName;
    }

    get pool() {
        if (!this._pool) {
            this._pool = new CognitoUserPool({
                UserPoolId: process.env.REACT_APP_USER_POOL_ID,
                ClientId: process.env.REACT_APP_CLIENT_ID,
            });
        }
        return this._pool;
    }

    get user() {
        if (!this._user) {
            this._user = new CognitoUser({
                Username: this.userName,
                Pool: this.pool,
            });
        }
        return this._user;
    }

    getUserAttributes() {
        return new Promise((resolve, reject) => {
            this.user.getUserAttributes((err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    signUp(name, email, password) {
        return new Promise((resolve, reject) => {
            const dataName = {
                Name: "preferred_username",
                Value: this.userName,
            };
            const dataPersonName = { Name: "name", Value: name };
            const dataEmail = { Name: "email", Value: email };

            const attributeList = [
                new CognitoUserAttribute(dataEmail),
                new CognitoUserAttribute(dataPersonName),
                new CognitoUserAttribute(dataName),
            ];

            this.pool.signUp(this.userName, password, attributeList, null, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    this._user = res.user;
                    resolve(res);
                }
            });
        });
    }

    confirmRegistration(code) {
        code = code.trim();
        return new Promise((resolve, reject) => {
            this.user.confirmRegistration(code, true, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    signIn(password, newPassword = null) {
        return new Promise((resolve, reject) => {
            const authenticationDetails = new AuthenticationDetails({ Username: this.userName, Password: password });
            const user = this.user;

            user.authenticateUser(authenticationDetails, {
                onSuccess: (res) => {
                    this.populateUser().then(() => resolve(res));
                },
                onFailure: (err) => reject(err),
                newPasswordRequired: function (userAttributes) {
                    delete userAttributes.email_verified;
                    user.completeNewPasswordChallenge(newPassword, userAttributes, this);
                },
            });
        });
    }

    populateUser() {
        return this.getUserAttributes().then((userAttibutes) => {
            for (const obj of userAttibutes) {
                if (obj.getName() === "name") {
                    this.nome = obj.getValue();
                } else if (obj.getName() === "email") {
                    this.email = obj.getValue();

                    if (!this.userName) {
                        this.userName = this.email;
                    }
                }
            }

            return axios.get(`/usuario`).then((result) => {
                this.aceitouTermos = result.data.aceitouTermos;
            });
        });
    }

    signOut() {
        return this.user.signOut();
    }

    forgotPassword() {
        return new Promise((resolve, reject) => {
            this.user.forgotPassword({
                onSuccess: (res) => resolve(res),
                onFailure: (err) => reject(err),
            });
        });
    }

    confirmPassword(code, newPassword) {
        return new Promise((resolve, reject) => {
            this.user.confirmPassword(code, newPassword, {
                onSuccess: (res) => resolve(res),
                onFailure: (err) => reject(err),
            });
        });
    }

    changePassword(oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            this.user.changePassword(oldPassword, newPassword, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    async checkIfAlreadyAuthenticated(callback) {
        var cognitoUser = this.pool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    return;
                }
                this._user = cognitoUser;
                this.populateUser().then(() => callback(this));
            });
        }
    }

    getTokenFromPool(callback) {
        var cognitoUser = this.pool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    return;
                }
                
                callback({ acessoToken: session.getAccessToken().getJwtToken(), idToken: session.getIdToken().getJwtToken() });
            });
        }
    }

    getCognitoId() {
        return this.user.username;
    }

    getToken() {
        return this.user.getSignInUserSession().getAccessToken().getJwtToken();
    }
}
