import { TextField } from "@material-ui/core";
import { useField } from "formik";
import React from "react";

const TextFieldDefault = ({ helperText, fullWidth = true, label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <TextField
                error={meta.touched && Boolean(meta.error)}
                fullWidth={fullWidth}
                id={props.id}
                name={props.name}
                label={label}
                variant="outlined"
                helperText={helperText || (meta.touched && meta.error && meta.error)}
                {...field}
                {...props}
            />
        </>
    );
};

export default TextFieldDefault;
