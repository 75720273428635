import React from "react";
import TextFieldDefault from "../TextFieldDefault/TextFieldDefault";
import TextMaskCustom, { maskToArrayMask } from "./TextMaskCustom";

const maskCep = maskToArrayMask("99999-999");

export default function CepTextField(props) {
    return (
        <TextFieldDefault
            {...{
                InputProps: {
                    inputComponent: TextMaskCustom,
                },
                inputProps: {
                    mask: maskCep,
                    guide: false,
                },
            }}
            {...props}
        />
    );
}
