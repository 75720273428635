import { Container, LinearProgress, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUsuarioLogado } from "App";
import { useResgateContext } from "App.routes";
import GiftCardField from "components/GiftCardField/GiftCardField";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "services/axios-utils";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    loader: {
        marginTop: 4,
        width: "95%",
    },
}));
export default function ResgatarGiftCard() {
    const classes = useStyles();
    const history = useHistory();
    const [codigo, setCodigo] = useState("");
    const [validando, setValidando] = useState(false);
    const context = useResgateContext();
    const usuario = useUsuarioLogado();

    useEffect(() => {
        if (context.giftCard) {
            setCodigo(context.giftCard.serial);
        }
    }, [context.giftCard]);

    const verificarGiftCard = (event) => {
        const valor = event.target.value;
        setCodigo(valor);

        if (valor.length === 19) {
            if (!usuario) {
                toast.error("É preciso primeiro fazer o login para resgatar o gift card");
                return;
            }

            setValidando(true);

            axios
                .get(`/gift-card/${valor}/validar`)
                .then((result) => {
                    if (!result.data.valid) {
                        toast.error(result.data.message);
                    } else {
                        context.setGiftCard(result.data.giftCard);
                        history.push("/informacoes-adicionais");
                    }
                })
                .catch((err) => {
                    toast.error("Ocorreu um erro ao consultar gift card. " + err.message);
                    throw err;
                })
                .finally(() => setValidando(false));
        }
    };

    return (
        <Container className={classes.paper}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
                Já tenho o código
            </Typography>
            <GiftCardField
                valueChangeHandler={verificarGiftCard}
                onSubmit={verificarGiftCard}
                value={codigo}
                disabled={validando}
                mask="AAAA-AAAA-AAAA-AAAA"
            />
            {validando && <LinearProgress className={classes.loader} />}
        </Container>
    );
}

const useStylesStepper = makeStyles((theme) => ({
    root: {
        backgroundColor: "inherit",
    },
}));
export const CadastroStepper = ({ activeStep }) => {
    const classes = useStylesStepper();

    return (
        <Stepper classes={{ root: classes.root }} alternativeLabel activeStep={activeStep}>
            <Step>
                <StepLabel>Informar Gift Card</StepLabel>
            </Step>
            <Step>
                <StepLabel>Informaçoes Cadastrais</StepLabel>
            </Step>
            <Step>
                <StepLabel>Dados do Pet</StepLabel>
            </Step>
            <Step>
                <StepLabel>Confirmação</StepLabel>
            </Step>
        </Stepper>
    );
};
