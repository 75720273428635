import { history } from "App";
import axios from "axios";
import { User } from "./user-service";
import { toast } from "react-toastify";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 15000,
});

instance.interceptors.request.use(
    function (config) {
        new User().getTokenFromPool((token) => {
            config.headers.authorization = `Bearer ${token.acessoToken}`;
            config.headers.user = `${token.idToken}`;
        });
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (config) {
        return config;
    },
    function (error) {
        if (error.response?.status === 403) {
            toast.error("Você não está logado");
            history.push("/gift-card");
        }
        return Promise.reject(error);
    }
);

export default instance;

export const headerWithToken = (user) => {
    if (!user) {
        return {};
    }

    const token = user.getToken();

    return {
        headers: {
            authorization: `Bearer ${token}`,
        },
    };
};
