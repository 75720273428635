import Carousel, { autoplayPlugin, Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { Container } from "@material-ui/core";
import { LINK_WHATSAPP } from "App";
import banner1 from "assets/banner/BANNER_01.png";
import banner2 from "assets/banner/BANNER_02.png";
import banner3 from "assets/banner/BANNER_03.png";
import banner4 from "assets/banner/BANNER_04.png";
import { useEffect, useState } from "react";
import ImageMapper from "react-img-mapper";
import { useHistory } from "react-router-dom";
import Coberturas from "./Coberturas";
import ComoFunciona from "./ComoFunciona";
import PorqueEscolher from "./PorqueEscolher";

export default function PaginaInicial() {
    const [value, setValue] = useState(0);
    const [slides] = useState([<Banner2 />, <Banner1 />, <Banner3 />, <Banner4 />]);
    const onChange = (value) => {
        setValue(value);
    };

    return (
        <>
            <Carousel
                plugins={[
                    "infinite",
                    {
                        resolve: autoplayPlugin,
                        options: {
                            interval: 10000,
                        },
                    },
                ]}
                value={value}
                onChange={onChange}
                slides={slides}
                animationSpeed={1000}
            ></Carousel>
            <Dots value={value} onChange={onChange} number={slides.length} />

            <Container maxWidth="md">
                <div className="iframe-container">
                    <iframe
                        width="1280"
                        height="720"
                        src="https://www.youtube.com/embed/fhJ865BNyks"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>
            </Container>
            <br />
            <br />
            <ComoFunciona />
            <br />
            <br />
            <PorqueEscolher />
            <br />
            <br />
            <Coberturas />
            <br />
            <br />
            <Container maxWidth="lg">
                <img
                    alt=""
                    style={{ width: "100%" }}
                    src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/cachorroInferior.png"
                />
            </Container>
        </>
    );
}

const ImageMap = ({ src, to, map }) => {
    const { width } = useWindowDimensions();
    const history = useHistory();
    const [imgWidth, setImgWidth] = useState(width - 10);

    useEffect(() => {
        setTimeout(() => {
            setImgWidth(width - 15);
        }, 500);
    }, [width]);

    const navigate = () => {
        if (to) {
            if (to.startsWith("https")) {
                window.open(to, "_blank");
            } else {
                history.push(to);
            }
        }
    };

    return <ImageMapper parentWidth={imgWidth} src={src} responsive active={true} onClick={navigate} map={map} />;
};

const Banner1 = (props) => {
    return (
        <ImageMap
            src={banner1}
            to="sobre"
            map={{
                name: "mapBanner1",
                areas: [
                    {
                        id: "sobre",
                        title: "Sobre",
                        shape: "circle",
                        href: "#",
                        coords: [1030, 575, 135],
                    },
                ],
            }}
        />
    );
};

const Banner2 = (props) => {
    return (
        <ImageMap
            src={banner2}
            to="gift-card"
            map={{
                name: "mapBanner2",
                areas: [
                    {
                        id: "cadastre",
                        title: "Cadastre seu código",
                        shape: "rect",
                        href: "#",
                        coords: [210, 680, 1790, 540],
                    },
                ],
            }}
        />
    );
};

const Banner3 = (props) => {
    return (
        <ImageMap
            src={banner3}
            to="planos"
            map={{
                name: "mapBanner3",
                areas: [
                    {
                        id: "planos",
                        title: "Planos",
                        shape: "rect",
                        href: "#",
                        coords: [490, 700, 890, 520],
                    },
                ],
            }}
        />
    );
};

const Banner4 = (props) => {
    return (
        <ImageMap
            src={banner4}
            to={LINK_WHATSAPP}
            map={{
                name: "mapBanner4",
                areas: [
                    {
                        id: "saibaMais",
                        title: "Saiba Mais",
                        shape: "rect",
                        href: LINK_WHATSAPP,
                        coords: [990, 590, 1410, 430],
                    },
                ],
            }}
        />
    );
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
