/* eslint-disable */
import { setLocale } from "yup";

const translation = {
    mixed: {
        default: "Valor inválido",
        required: "Obrigatório",
        oneOf: "Informe um dos seguintes valores: ${values}",
        notOneOf: "Não se deve incluir um dos seguintes valores: ${values}",
    },
    string: {
        length: "Deve ter exatos ${length} caracteres",
        min: "Deve ter pelo menos ${min} caracteres",
        max: "Deve ter no máximo ${max} caracteres",
        matches: "Formato inválido",
        email: "E-mail inválido",
        url: "URL inválida",
        trim: "Não pode possuir espaços antes e depois",
        lowercase: "Use apenas letras minúsculas",
        uppercase: "Use apenas letras maiúsculas",
        min_words: "Deve ter pelo menos {{min}} palavras (atualmente {{val}})",
    },
    number: {
        min: "Deve ser maior ou igual a ${min}",
        max: "Deve ser menor ou igual a ${max}",
        lessThan: "Deve ser menor que ${less}",
        moreThan: "Deve ser maior que ${more}",
        notEqual: "Deve ser diferente de ${notEqual}",
        positive: "Deve ser um número positivo",
        negative: "Deve ser um número negativo",
        integer: "Deve ser um número inteiro",
    },
    date: {
        min: "Data fim posterior à data de início",
        max: "Deve ser anterior à data de fim",
    },
};

setLocale(translation);
