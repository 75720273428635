import { Avatar, Link, Button, Container, TextField, Typography, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { User } from "services/user-service";
import { toast } from "react-toastify";

export const EsqueceuSenha = ({ classes, setEsqueceuSenha }) => {
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const lembrar = (event) => {
        event.preventDefault();

        const user = new User(email);

        setLoading(true);
        user.forgotPassword()
            .then((data) => {
                history.push("/confirmar-esqueci-senha?email=" + encodeURIComponent(email));
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Não foi possivel realizar a operação");
                throw err;
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Esqueci minha senha
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Um e-mail será enviado com as instruções para trocar sua senha.
                </Typography>
                <form onSubmit={lembrar}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(ev) => setEmail(ev.target.value)}
                    />
                    <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Enviar e-mail
                    </Button>
                </form>
                {loading && <CircularProgress size={24} />}
                <Typography variant="body2">
                    <Link href="#" onClick={() => setEsqueceuSenha(false)}>
                        Voltar
                    </Link>
                </Typography>
            </div>
        </Container>
    );
};
