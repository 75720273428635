import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import TextMaskCustom from "components/TextMaskCustom/TextMaskCustom";
import React from "react";

const useStyles = makeStyles((theme) => ({
    idInput: {
        fontSize: "1.2rem",
    },
}));
const GiftCardField = ({ label, value, error, valueChangeHandler, onSubmit, blurHandler, disabled, helperText, maxLength, mask }) => {
    const classes = useStyles();

    const keyPressHandler = (ev) => {
        if (ev.key === "Enter") {
            onSubmit && onSubmit(ev);
            ev.preventDefault();
        }
    };

    const inputComponent = mask ? { inputComponent: TextMaskCustom } : {};
    const inputProps = mask
        ? {
              mask,
              guide: false,
              maxLength,
          }
        : {
              maxLength,
          };

    return (
        <>
            <TextField
                id="giftCodeField"
                label={label}
                variant="outlined"
                value={value}
                fullWidth
                onChange={valueChangeHandler}
                onBlur={blurHandler}
                className={classes.idField}
                error={error}
                autoFocus
                disabled={disabled}
                helperText={helperText}
                onKeyPress={keyPressHandler}
                InputProps={{
                    ...inputComponent,
                    endAdornment: value && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Clear"
                                onClick={() => valueChangeHandler({ target: { value: "" } })}
                                disabled={disabled}
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    inputProps: { ...inputProps },
                    classes: {
                        input: classes.idInput,
                    },
                }}
            />
        </>
    );
};

export default GiftCardField;
