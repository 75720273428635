import React from "react";
import TextFieldDefault from "../TextFieldDefault/TextFieldDefault";
import TextMaskCustom, { maskToArrayMask } from "./TextMaskCustom";

const mask8Digits = maskToArrayMask("(99) 9999-9999");
const mask9Digits = maskToArrayMask("(99) 99999-9999");

export default function PhoneTextField(props) {
    return (
        <TextFieldDefault
            {...{
                InputProps: {
                    inputComponent: TextMaskCustom,
                },
                inputProps: {
                    mask: (value) => {
                        return value.length < 15 ? mask8Digits : mask9Digits;
                    },
                    guide: false,
                },
            }}
            {...props}
        />
    );
}
