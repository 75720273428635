import { Container, Typography, ListItem, ListItemAvatar, ListItemText, List, Button } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React from "react";

export default function PorqueEscolher() {
    const [panelAberto, setPanelAberto] = React.useState(null);

    const handleChange = (panel) => (event) => {
        setPanelAberto(panelAberto !== panel ? panel : null);
    };

    const PaginaInicialListItem = ({ children, titulo, nome }) => {
        const icone = panelAberto === nome ? <Remove /> : <Add />;
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <img width="50px" alt="" src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/pata2.png" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Button variant="text" startIcon={icone} onClick={handleChange(nome)}>
                                <Typography variant="h6">{titulo}</Typography>
                            </Button>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography variant="body1">{panelAberto === nome ? children : ""}</Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    };

    return (
        <Container maxWidth="lg">
            <img
                alt=""
                style={{ width: "100%" }}
                src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/porqueEscolher.png"
            />
            <br />
            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <PaginaInicialListItem nome="panel1" titulo="Não temos limite de idade na contratação">
                    Aceitamos Pets de todas as idades na contratação
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel2" titulo="Não temos copartipação na utilização">
                    Você não precisará desembolsar nada no momento em que for usar uma das coberturas, o único valor que pagará é referente
                    ao gift card e nada mais.
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel3" titulo="Você estará segurado por até duas das maiores seguradoras do Mundo">
                    É isso mesmo, você poderá ser coberto pelas Seguradoras Mapfre e Sul América, duas dos maiores grupos seguradores do
                    mundo e, com certeza, você será bem atendido e ficará bem aparado.
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel4" titulo="Proteja seu Pet em todas as capitais Nacionais">
                    Nossa extensão de atendimento será em todo Brasil, portanto se a Proteja seu Pet ainda não está perto de você, não se
                    preocupe, logo estaremos chegando para lhe atender.
                </PaginaInicialListItem>
            </List>
        </Container>
    );
}
