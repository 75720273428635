import React, { useMemo } from "react";
import MaskedInput from "react-text-mask";

export function maskToArrayMask(mask) {
    if (typeof mask === "function") return mask;

    const arrayMask = [];
    for (const c of mask) {
        switch (c) {
            case "9":
            case "0":
                arrayMask.push(/\d/);
                break;
            case "A":
                arrayMask.push(/\w/);
                break;
            default:
                arrayMask.push(c);
        }
    }
    return arrayMask;
}

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    const mask = useMemo(() => maskToArrayMask(props.mask), [props.mask]);

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={"\u2000"}
            showMask
        />
    );
};

export default TextMaskCustom;
