import { Checkbox, CircularProgress, Container, FormControlLabel, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useUsuarioLogado } from "App";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "services/axios-utils";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        maxWidth: "767px",
    },
    paragrafo: {
        textIndent: "30px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.875rem",
        fontFamily: "Gotham, Roboto, Helvetica, Arial, 'sans-serif'",
    },
    titulo: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.875rem",
        fontFamily: "Gotham, Roboto, Helvetica, Arial, 'sans-serif'",
        fontWeight: "bold",
    },
    aceiteDiv: {
        backgroundColor: "white",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "10px",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function Paragrafo(props) {
    const classes = useStyles();
    return (
        <ListItem>
            <Typography align="justify" className={classes.paragrafo}>
                {props.children}
            </Typography>
        </ListItem>
    );
}

function Titulo(props) {
    const classes = useStyles();
    return (
        <ListItem>
            <Typography align="left" className={classes.titulo}>
                {props.children}
            </Typography>
        </ListItem>
    );
}

export default function TermosCondicoes() {
    const classes = useStyles();
    const referenceNode = useRef();
    const aceiteDivRef = useRef();
    const [habilitar, setHabilitar] = useState(false);
    const [salvando, setSalvando] = useState(false);
    const usuario = useUsuarioLogado();
    const history = useHistory();

    
    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (usuario && currPos.y * -1 + (window.innerHeight - aceiteDivRef.current.clientHeight) > referenceNode.current.clientHeight) {
                setHabilitar(true);
            }
        },
        [],
        referenceNode
    );

    const aceitarTermos = (event) => {
        if (!event.target.value) {
            return;
        }
        setSalvando(true);
        axios
            .put("usuario/aceitar-termos")
            .then(() => {
                usuario.aceitouTermos = true;
                history.push("gift-card");
            })
            .catch((err) => {
                setSalvando(false);
                toast.error("Erro ao aceitar os termos");
                throw err;
            });
    };

    const AceiteBar = () => {
        if (usuario) {
            return <div className={classes.aceiteDiv} ref={aceiteDivRef}>
                <FormControlLabel
                    control={<Checkbox name="aceite" disabled={!habilitar && !salvando} value={true} />}
                    label="Declaro que li e aceito os termos e condições"
                    onChange={(value) => aceitarTermos(value)}
                />
                {salvando && <CircularProgress size={24} />}
            </div>;
        }
        return '';
    };

    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h6" align="center">
                TERMOS E CONDIÇÕES DE USO
            </Typography>
            <br />
            <div>
                <List ref={referenceNode}>
                    <Titulo>SITE DA PROTEJA SEU PET</Titulo>
                    <Paragrafo>
                        O presente Termos e Condições Gerais de Uso (&quot;Termos de Uso&quot;) aplica-se ao uso dos serviços (“Serviço
                        Pet”) oferecidos através do site www.protejaseupet.com.br (&quot;SITE&quot;), pessoa jurídica de direito privado,
                        inscrita no CNPJ/MF sob nº 40.174.743/0001-00, incluindo as diversas funcionalidades que possibilitem a busca de
                        fornecedores e prestadores de serviços do segmento Pet (“Serviços” e “Produtos”), contratação de Serviços e
                        aquisição do produto.
                    </Paragrafo>
                    <Titulo>
                        Bem-vindo ao <b>SITE</b> da <b>PROTEJA SEU PET</b>.
                    </Titulo>
                    <Paragrafo>
                        A seguir apresentamos a você (<b>USUÁRIO</b>) os Termos e Condições de Uso, documento que relaciona as principais
                        regras a serem observadas por todos que acessam o <b>SITE</b> da <b>PROTEJA SEU PET</b> ou utilizam suas
                        funcionalidades. Como condição para acesso e uso das funcionalidades oferecidas pelo <b>SITE</b>, o <b>USUÁRIO</b>
                         deve declarar que fez a leitura completa e atenta das regras deste documento e de sua Política de Privacidade,
                        estando plenamente ciente e de acordo com elas.
                    </Paragrafo>
                    <Titulo>1. SERVIÇOS OFERECIDOS</Titulo>
                    <Paragrafo>
                        1.1. O <b>SITE</b> da <b>PROTEJA SEU PET</b> tem como intuito proporcionar ao <b>USUÁRIO</b> uma plataforma de
                        cadastro de seu(s) animal(is) de estimação (cão e/ou gato), bem como: (a) acesso ao serviço de assistência prestado
                        pela empresa parceira, cujos termos serão fornecidos em detalhe ao <b>USUÁRIO</b> em caso de contratação; (b) outras
                        funcionalidades ou serviços que futuramente venham a ser implementados na plataforma, cuja disponibilização ocorrerá
                        de acordo com a conveniência do <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        1.2. A <b>PROTEJA SEU PET</b> poderá disponibilizar aos seus <b>USUÁRIOS</b> o acesso à novas soluções, sejam elas
                        de sua propriedade ou de terceiros, como novos produtos, meios de pagamento, aplicativos e outros. A utilização pelo{" "}
                        <b>USUÁRIO</b> se dará mediante aceitação dos presentes Termos e Condições de Uso bem como dos Termos e Condições de
                        Uso da solução disponibilizada por terceiro, quando aplicado.
                    </Paragrafo>
                    <Paragrafo>
                        1.3. Para acessar o <b>SITE</b> da <b>PROTEJA SEU PET</b> de forma segura e com todas suas funcionalidades é de
                        inteira responsabilidade do <b>USUÁRIO</b> dispor de dispositivos e equipamentos compatíveis, assim como, conexão à
                        Internet com antivírus e firewall habilitados, softwares devidamente atualizados – tais como o sistema operacional
                        do dispositivo móvel – além da adoção de medidas de segurança mínima, o que inclui, mas não se limita à utilização
                        de senha segura.
                    </Paragrafo>
                    <Paragrafo>
                        1.4. A <b>PROTEJA SEU PET</b> envidará seus esforços para a disponibilidade contínua e permanente do <b>SITE</b>. No
                        entanto, poderá ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou
                        mesmo gerada por motivo de força maior, como desastres naturais, falhas ou colapsos nos sistemas centrais de
                        comunicação e acesso à Internet ou fatos de terceiro que fogem de sua esfera de vigilância e responsabilidade.
                    </Paragrafo>
                    <Paragrafo>
                        1.4.1. Se isso ocorrer, a <b>PROTEJA SEU PET</b> fará o que estiver ao seu alcance para restabelecer o acesso ao 
                        <b>SITE</b> o mais breve possível, dentro das limitações técnicas de seus serviços e serviços de terceiros, dos
                        quais a <b>PROTEJA SEU PET</b> depende para ficar on-line.
                    </Paragrafo>
                    <Titulo>
                        2. Funcionalidades do <b>SITE</b> da <b>PROTEJA SEU PET</b>
                    </Titulo>
                    <Paragrafo>
                        2.1. Para ter acesso aos serviços oferecidos pelo <b>SITE</b> da <b>PROTEJA SEU PET</b>, o <b>USUÁRIO</b> deverá
                        possuir uma <b>Conta de Acesso</b>, a qual pode ser criada mediante preenchimento de formulário especifico no{" "}
                        <b>SITE</b>.
                    </Paragrafo>
                    <Paragrafo>
                        2.2. Os serviços do <b>SITE</b> da <b>PROTEJA SEU PET</b> são destinados a pessoas físicas maiores de 18 (dezoito)
                        anos e plenamente capazes. Caso o <b>USUÁRIO</b> não esteja de acordo com o exigido, não deverá prosseguir com a
                        criação da <b>Conta de Acesso</b> ou sua utilização, sob pena de ser caracterizada má-fé.
                    </Paragrafo>
                    <Paragrafo>
                        2.3. A <b>PROTEJA SEU PET</b> poderá alterar os critérios de elegibilidade de acesso e utilização do <b>SITE</b> a
                        qualquer momento, sem que para isso tenha de fazer qualquer tipo de comunicação ou aviso prévio ao <b>USUÁRIO</b>. A{" "}
                        <b>PROTEJA SEU PET</b> também possui a faculdade de se recusar a conceder ou cancelar <b>Conta de Acesso</b> a
                        qualquer pessoa ou entidade que a utilizar de forma fraudulenta, violar ou tentar violar os presentes Termos de Uso,
                        a Política de Privacidade, o Contrato de Adesão ou qualquer documento legal da <b>PROTEJA SEU PET</b>,
                        disponibilizado ou não no <b>SITE</b>.
                    </Paragrafo>
                    <Paragrafo>
                        2.4. Se a qualquer momento for constatado que o <b>USUÁRIO</b> forneceu informações falsas ou não condizentes com a
                        realidade, a <b>PROTEJA SEU PET</b> se reserva ao direito de cancelar sua <b>Conta de Acesso</b>, sem prejuízo de
                        adotar as medidas que entender cabíveis.
                    </Paragrafo>
                    <Paragrafo>
                        A <b>PROTEJA SEU PET</b> poderá cancelar Contas de Acesso que tenham sido criadas com informações falsas ou que
                        estejam sendo utilizadas em violação aos documentos legais da <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        2.5. A <b>PROTEJA SEU PET</b> se reserva ao direito de incluir e retirar novas (ou já existentes) funcionalidades e
                        ferramentas do <b>SITE</b> segundo sua conveniência, podendo atualizá-lo sempre que entender necessário para
                        oferecer os melhores serviços e produtos ao <b>USUÁRIO</b>.
                    </Paragrafo>
                    <Paragrafo>
                        2.6. O <b>USUÁRIO</b> poderá exercer o seu direito de arrependimento em até 7 (sete) dias contados da aquisição de
                        determinado produto ou serviço, devendo se utilizar das formas de contato disponibilizadas no <b>SITE</b> da{" "}
                        <b>PROTEJA SEU PET</b> para dar início ao procedimento.
                    </Paragrafo>
                    <Paragrafo>
                        2.6.1. Após o contato inicial com a <b>PROTEJA SEU PET</b>, o <b>USUÁRIO</b> receberá confirmação da solicitação de
                        seu direito de arrependimento e instruções detalhadas sobre como proceder.
                    </Paragrafo>
                    <Paragrafo>
                        2.6.2. A <b>PROTEJA SEU PET</b> avaliará a solicitação do <b>USUÁRIO</b>, analisando diversos elementos para
                        averiguar a elegibilidade do direito de arrependimento. Explicamos ao <b>USUÁRIO</b> como proceder e o funcionamento
                        do direito de arrependimento para os casos aplicáveis.
                    </Paragrafo>
                    <Titulo>
                        3. Das responsabilidades e obrigações do <b>USUÁRIO</b>
                    </Titulo>
                    <Paragrafo>
                        3.1. Utilizar com retidão e de forma ética o <b>SITE</b> da <b>PROTEJA SEU PET</b>, obedecendo aos propósitos aqui
                        estabelecidos, sempre respeitando as condições que regem sua utilização e finalidade.
                    </Paragrafo>
                    <Paragrafo>
                        3.2. O <b>USUÁRIO</b> se compromete a fornecer dados cadastrais corretos, completos e atualizados para que o{" "}
                        <b>SITE</b> possa funcionar corretamente, além de informar canal de contato apto a ser acionado pela{" "}
                        <b>PROTEJA SEU PET</b> para o melhor cumprimento dos serviços.
                    </Paragrafo>
                    <Paragrafo>
                        Nunca forneça informações falsas ou incorretas quando estiver utilizando o <b>SITE</b> da <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        3.3. Manter o sigilo dos dados de sua <b>Conta de Acesso</b> em relação a terceiros, não divulgando ou
                        compartilhando sua senha com quem quer que seja, inclusive disponibilizando-a em locais onde outras pessoas possam
                        ter acesso ou para aplicativos/softwares acessarem a conta de forma automatizada, sob pena de exclusão da{" "}
                        <b>Conta de Acesso</b>, além de ter de ressarcir os danos que sobrevierem dessa divulgação indevida. O{" "}
                        <b>USUÁRIO</b> se obriga a preservar a confidencialidade e utilização individual de seu e-mail, senha de acesso e
                        eventuais outras formas de verificação de autenticidade da <b>Conta de Acesso</b> que a <b>PROTEJA SEU PET</b> venha
                        a implementar.
                    </Paragrafo>
                    <Paragrafo>
                        3.3.1. A <b>Conta de Acesso</b> é individual, de uso exclusivo e intransferível, portanto o<b>USUÁRIO</b> deve
                        protegê-la e mantê-la de modo confidencial, não podendo repassá-la a terceiros, sob qualquer hipótese, tal qual os
                        componentes de segurança adicionais que possuir, conforme descrito acima.
                    </Paragrafo>
                    <Paragrafo>
                        3.4. O <b>USUÁRIO</b> deverá adotar senha forte com o intuito de dificultar a ação de “experts” em computação de
                        adivinhá-la ou quebrá-la por mecanismos de força bruta. Entende-se por senha forte aquela que:
                    </Paragrafo>
                    <Paragrafo>
                        i. Em caso de senha alfanumérica, possui ao menos 8 caracteres, sendo 1 letra maiúscula, 1 letra minúscula, 1
                        número, 1 símbolo ou caractere especial e
                    </Paragrafo>
                    <Paragrafo>
                        ii. Em caso de senha numérica, não representa sequências numéricas relacionadas a informações pessoais ou de fácil
                        dedução, tais como elementos do próprio nome, datas comemorativas ou repetição de caracteres iguais, a exemplo, mas
                        não se limitando a: 1234, 0000, 01012011.
                    </Paragrafo>
                    <Paragrafo>
                        3.4.1. Caso o <b>USUÁRIO</b> suspeite que a confidencialidade de sua senha foi quebrada ou que houve o
                        comprometimento de algum dispositivo de autenticação, ele deverá proceder sua troca ou atualização o mais rápido
                        possível. Se não conseguir acessar a Internet para obter suporte, deverá buscar o atendimento dentre os canais
                        disponíveis.
                    </Paragrafo>
                    <Paragrafo>
                        3.5. O <b>USUÁRIO</b> deverá adotar medidas em seus dispositivos tecnológicos para evitar o acesso físico e lógico
                        por terceiros não autorizados, tais como utilização de senha e/ou biometria.
                    </Paragrafo>
                    <Paragrafo>
                        3.6 O <b>USUÁRIO</b> reconhece e afirma que todos os acessos realizados após sua autenticação digital bem-sucedida
                        são interpretados como tendo sido feitos por ele próprio de forma incontestável. Por isso, será responsável por: (i)
                        todos os acessos e operações no <b>SITE</b> da <b>PROTEJA SEU PET</b> praticados com uso de sua{" "}
                        <b>Conta de Acesso</b>;
                    </Paragrafo>
                    <Paragrafo>
                        3.7. Sempre que não houver contato inicial provocado pelo próprio <b>USUÁRIO</b>, eles deverão ignorar e/ou rejeitar
                        quaisquer espécies de abordagens em nome da <b>PROTEJA SEU PET</b> independentemente do meio utilizado, pela
                        suspeita de que sejam falsas, especialmente se consistirem em:
                    </Paragrafo>
                    <Paragrafo>
                        i. pedido de divulgação, confirmação, conferência ou atualização de dados de conta ou informações pessoais;
                    </Paragrafo>
                    <Paragrafo>
                        ii. requisição, confirmação, conferência ou atualização da <b>Conta de Acesso</b>, de componentes de segurança do{" "}
                        <b>USUÁRIO</b>, ou de dados que estejam contidos nesses dispositivos, ou forem os próprios;
                    </Paragrafo>
                    <Paragrafo>
                        iii. envio de <i>links</i> de Internet ou arquivos anexos;
                    </Paragrafo>
                    <Paragrafo>
                        iv. abordagem via mídia social por canal que não seja oficial ou anúncios em perfis ou páginas estranhas à{" "}
                        <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        3.7.1. O dever de cautela e de segurança da informação compete ao <b>USUÁRIO</b>, qualquer comportamento negligente
                        ou que envolva falta de atenção farão com que o <b>USUÁRIO</b> suporte todos os prejuízos que deu causa, por conta e
                        risco próprio.
                    </Paragrafo>
                    <Paragrafo>
                        Não compartilhe sua <b>Conta de Acesso</b> com ninguém, já que o único responsável por usos feitos a partir dessas
                        contas será o próprio <b>USUÁRIO</b>.
                    </Paragrafo>
                    <Paragrafo>
                        3.8. É de responsabilidade do <b>USUÁRIO</b> deixar as ferramentas de <b>AntiSpam</b>, filtros similares ou
                        configurações de redirecionamento de mensagens ou notificações ajustadas de modo que não interfiram no recebimento
                        dos comunicados e materiais da <b>PROTEJA SEU PET</b>, não sendo aceitável nenhuma escusa caso não tenha tido acesso
                        a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados.
                    </Paragrafo>
                    <Paragrafo>
                        3.9. Ao acessar o <b>SITE</b>, o <b>USUÁRIO</b> declara que irá respeitar todos os direitos de propriedade
                        intelectual de titularidade da <b>PROTEJA SEU PET</b>, tal qual todos os direitos referentes a terceiros que
                        porventura estejam, ou estiveram, de alguma forma disponíveis no <b>SITE</b>.
                    </Paragrafo>
                    <Paragrafo>
                        3.10 O <b>USUÁRIO</b> somente poderá reproduzir os conteúdos disponíveis no <b>SITE</b>, em especial suas marcas e 
                        <i>layout</i> do ambiente, desde que devidamente autorizado pela <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        3.11. As funcionalidades que compõem <b>SITE</b> são oferecidas na forma de prestação de serviço e licenciamento de
                        software, não conferindo ao <b>USUÁRIO</b> nenhum direito sobre o software utilizado pela <b>PROTEJA SEU PET</b> ou
                        sobre suas estruturas de informática que sustentam o referido <b>SITE</b>.
                    </Paragrafo>
                    <Paragrafo>
                        3.12 O <b>USUÁRIO</b> é o único e exclusivo responsável pelos usos feitos com sua <b>Conta de Acesso</b> ou acessos
                        realizados por meio do seu dispositivo móvel, responsabilizando-se por quaisquer usos que possam violar as
                        disposições da <b>PROTEJA SEU PET</b> ou direitos de terceiro.
                    </Paragrafo>
                    <Paragrafo>
                        3.13. O <b>USUÁRIO</b> está ciente de que poderá ter sua <b>Conta de Acesso</b> banida sem aviso prévio caso a
                        utilize para realizar qualquer atividade que viole direitos da <b>PROTEJA SEU PET</b> ou de terceiros.
                    </Paragrafo>
                    <Paragrafo>
                        O <b>USUÁRIO</b> se obriga a não violar direitos da <b>PROTEJA SEU PET</b> e de terceiros, bem como adotar
                        comportamento ético e respeitoso quando utilizar os serviços da <b>PROTEJA SEU PET</b>, sob pena de ter sua{" "}
                        <b>Conta de Acesso</b>
                         banida.
                    </Paragrafo>
                    <Paragrafo>
                        3.14. Não é permitido o acesso às áreas de programação do <b>SITE</b> da <b>PROTEJA SEU PET</b>, seu banco de dados
                        ou qualquer outro conjunto de informações que faça parte da atividade de desenvolvimento.
                    </Paragrafo>
                    <Paragrafo>
                        3.15. Também não é autorizado ao <b>USUÁRIO</b> realizar ou permitir que se realize engenharia reversa, nem
                        traduzir, descompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir,
                        emprestar, distribuir ou, de outra maneira, dispor das ferramentas do <b>SITE</b> da <b>PROTEJA SEU PET</b> e de
                        suas funcionalidades.
                    </Paragrafo>
                    <Paragrafo>
                        3.16. No <b>SITE</b> da <b>PROTEJA SEU PET</b> é proibida a utilização de qualquer tipo ou espécie de técnica de
                        mineração de dados, além de qualquer software que atue de modo automatizado, tanto para realizar operações
                        massificadas ou para quaisquer outras finalidades.
                    </Paragrafo>
                    <Paragrafo>
                        3.17. O <b>USUÁRIO</b> declara que não utilizará de técnicas para mascarar, esconder, alterar ou falsear seu
                        endereço de IP (Internet Protocolo) durante seu acesso ou tentativa de acesso <b>SITE</b> da <b>PROTEJA SEU PET</b>,
                        incluindo interferir no funcionamento regular do acesso entre seu dispositivo e os servidores do referido{" "}
                        <b>SITE</b> ou, ainda, explorar suas eventuais vulnerabilidades.
                    </Paragrafo>
                    <Paragrafo>
                        3.18. O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na suspensão das
                        funcionalidades ou exclusão da <b>Conta de Acesso</b> do <b>USUÁRIO</b>, conforme previsto neste documento.
                    </Paragrafo>
                    <Paragrafo>
                        3.19. A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade do <b>SITE</b> da{" "}
                        <b>PROTEJA SEU PET</b> em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de
                        boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração pela{" "}
                        <b>PROTEJA SEU PET</b> a direito de terceiro.
                    </Paragrafo>
                    <Paragrafo>
                        3.20. Na incidência de danos ao <b>SITE</b> da <b>PROTEJA SEU PET</b> ou a terceiros, o responsável se compromete a
                        arcar com todas as obrigações de indenizar o sujeito lesado.
                    </Paragrafo>
                    <Paragrafo>
                        3.21. O <b>USUÁRIO</b> se obriga a ressarcir a <b>PROTEJA SEU PET</b> de todas as condenações e prejuízos que sofrer
                        cuja origem for de atos praticados por sua <b>Conta de Acesso</b>, assumindo o polo passivo de ação judicial ou
                        procedimento administrativo e requerendo a exclusão da <b>PROTEJA SEU PET</b>, inclusive, devendo arcar totalmente
                        com as despesas e custas processuais atinentes, deixando-a livre de prejuízos e ônus. Utilize o <b>SITE</b> da{" "}
                        <b>PROTEJA SEU PET</b> de forma regular, não praticando atividades indevidas, como acessar áreas restritas aos
                        desenvolvedores. O <b>USUÁRIO</b> poderá ser responsabilizado e deverá ressarcir a <b>PROTEJA SEU PET</b> e
                        terceiros de quaisquer ações indevidas praticadas por meio de sua <b>Conta de Acesso</b>.
                    </Paragrafo>
                    <Titulo>
                        4. Da Isenção e Limitações de Responsabilidade da <b>PROTEJA SEU PET</b>
                    </Titulo>
                    <Paragrafo>
                        4.1. A <b>PROTEJA SEU PET</b> não se responsabiliza por quaisquer problemas, bugs, glitches ou funcionamentos
                        indevidos que ocorrerem nos dispositivos e equipamentos do <b>USUÁRIO</b> e sejam resultantes do uso regular do{" "}
                        <b>SITE</b> da <b>PROTEJA SEU PET</b> .
                    </Paragrafo>
                    <Paragrafo>
                        4.2. A <b>PROTEJA SEU PET</b> não garante e nem se responsabiliza pela disponibilidade integral e ininterrupta do{" "}
                        <b>SITE</b>, cujo correto funcionamento depende do acesso e tráfego de dados entre os dispositivos do <b>USUÁRIO</b>{" "}
                        e os servidores do <b>SITE</b>, não possuindo a <b>PROTEJA SEU PET</b> nenhuma ingerência e não sendo responsável
                        por eventuais falhas no tráfego destes dados e no acesso ao <b>SITE</b> decorrentes de falhas na Internet ou da
                        própria rede e serviços de telecomunicação prestados por provedores de acesso à Internet.
                    </Paragrafo>
                    <Paragrafo>
                        4.3. Sendo mero intermediário, a <b>PROTEJA SEU PET</b> não se se responsabiliza por danos, fatos ou vícios
                        decorrentes da prestação dos serviços assistenciais da empresa de assistência parceira, como transporte de animais,
                        procedimentos veterinários, implantação de chip, traslado funerário, bem como os demais serviços assistenciais
                        fornecidos pela parceira, cujos termos serão claramente expostos ao <b>USUÁRIO</b> quando da contratação.
                    </Paragrafo>
                    <Paragrafo>
                        4.4. A <b>PROTEJA SEU PET</b> não se responsabiliza por qualquer dano direto ou indireto ocasionado por eventos de
                        terceiros, como ataque de hackers, falhas no sistema, no servidor ou na conexão à internet, inclusive por ações de
                        softwares maliciosos como vírus, cavalos de tróia, e outros que possam, de algum modo, danificar o dispositivo ou a
                        conexão do <b>USUÁRIO</b> em decorrência do acesso, utilização ou navegação no <b>SITE</b> ou em qualquer das
                        soluções que sejam integradas como meio de pagamento, bem como a transferência de dados, arquivos, imagens, textos,
                        áudios ou vídeos contidos neste.
                    </Paragrafo>
                    <Paragrafo>
                        4.5. O <b>USUÁRIO</b> não possui qualquer direito para exigir a disponibilidade do <b>SITE</b> da{" "}
                        <b>PROTEJA SEU PET</b> conforme melhor lhes convém, tampouco poderá pleitear indenização ou reparação de danos em
                        caso de o <b>SITE</b> permanecer fora do ar, independente da motivação.
                    </Paragrafo>
                    <Paragrafo>
                        4.6. As disposições desta seção aplicam-se para as soluções, meios de pagamentos e novos produtos que venham a ser
                        disponibilizados pela <b>PROTEJA SEU PET</b> e seus parceiros.
                    </Paragrafo>
                    <Titulo>
                        5. Privacidade do <b>USUÁRIO</b> no <b>SITE</b> da <b>PROTEJA SEU PET</b>.
                    </Titulo>
                    <Paragrafo>
                        5.1. Ao adquirir ou encomendar um produto através da <b>PROTEJA SEU PET</b>, algumas informações do <b>USUÁRIO</b>,
                        como por exemplo seu nome, e-mail, endereço, número de telefone, número e data de expiração de seu cartão de
                        crédito, entre outras, podem ser armazenadas. Ficará armazenado, também, seu nome de <b>USUÁRIO</b> e senha, para
                        que futuramente possam ser efetuadas novas compras com mais facilidade.
                    </Paragrafo>
                    <Paragrafo>
                        5.2. O <b>USUÁRIO</b> tem a opção de receber e-mail e notificações contendo informações, promoções e avisos
                        importantes. Se o <b>USUÁRIO</b> não quiser mais receber tais notificações, poderá optar por isso alterando as suas
                        preferências. Ocasionalmente podemos usar seus dados para pesquisas de satisfação e pós-vendas (via website e outros
                        meios de comunicação, como chats, e-mails, SMS e redes sociais). Se você responder a essas pesquisas, seus dados
                        serão preservados como descrito acima.
                    </Paragrafo>
                    <Paragrafo>
                        5.3. Os dados do animal de estimação, como espécie, raça, sexo e pelagem, informados pelo <b>USUÁRIO</b> no
                        cadastro, poderão ser utilizados para personalização de ofertas, promoções especiais e marketing direcionado e
                        poderão ser fornecidos para as empresas parceiras para os mesmos fins.
                    </Paragrafo>
                    <Titulo>6. Canal de comunicação</Titulo>
                    <Paragrafo>
                        6.1. O <b>USUÁRIO</b> deverá entrar em contato em caso de qualquer dúvida com relação às disposições constantes
                        destes Termos de Uso por meio dos canais de atendimento da <b>PROTEJA SEU PET</b> apontados a seguir:
                        comercial@protejaseupet.com.br; financeiro@protejaseupet.com.br; (51) 99501-4845
                    </Paragrafo>
                    <Paragrafo>
                        6.2. No tocante à requisição dos serviços assistenciais contratados junto à empresa parceira, o contato deverá ser
                        feito diretamente com o canal de atendimento disponibilizado pela mesma.
                    </Paragrafo>
                    <Titulo>7. Disposições Gerais</Titulo>
                    <Paragrafo>
                        7.1. Os presentes Termos e Condições de Uso estão sujeitos a constante melhoria e aprimoramento. Assim, a{" "}
                        <b>PROTEJA SEU PET</b> se reserva ao direito de modificá-los a qualquer momento, conforme a finalidade do{" "}
                        <b>SITE</b> ou conveniência de sua controladora, tal qual para adequação e conformidade legal de disposição de lei
                        ou norma que tenha força jurídica equivalente, cabendo ao <b>USUÁRIO</b> verificá-lo sempre que efetuar o acesso ao{" "}
                        <b>SITE</b> da <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        7.2. Ocorrendo atualizações neste documento ou na Política de Privacidade Global, a <b>PROTEJA SEU PET</b>{" "}
                        notificará o <b>USUÁRIO</b> mediante as ferramentas disponíveis e no <b>SITE</b> e/ou os meios de contato fornecidos
                        pelo <b>USUÁRIO</b>.
                    </Paragrafo>
                    <Paragrafo>
                        7.3. Ao navegar pelo <b>SITE</b> da <b>PROTEJA SEU PET</b> e utilizar suas funcionalidades, o <b>USUÁRIO</b> aceita
                        guiar-se pelos Termos e Condições de Uso e pela Política de Privacidade Global que se encontram vigentes na data de
                        acesso. Por isso, é recomendável que o <b>USUÁRIO</b> se mantenha atualizado.
                    </Paragrafo>
                    <Paragrafo>
                        7.4. A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não
                        constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a
                        qualquer tempo.
                    </Paragrafo>
                    <Paragrafo>
                        7.5. Caso alguma disposição destes Termos de Uso ou da Política de Privacidade publicadas no <b>SITE</b> da{" "}
                        <b>PROTEJA SEU PET</b> for julgada inaplicável ou sem efeito, o restante de ambos os documentos continua a viger,
                        sem a necessidade de medida judicial que declare tal assertiva.
                    </Paragrafo>
                    <Paragrafo>
                        7.6. O <b>SITE</b> da <b>PROTEJA SEU PET</b> tem como base a data e horários oficiais de Brasília.
                    </Paragrafo>
                    <Titulo>8. Aceitação do Termo de Uso</Titulo>
                    <Paragrafo>
                        8.1 O <b>USUÁRIO</b> declara ter lido, entendido e que aceita todas as regras, condições e obrigações.
                        Reservamos-vos o direito de atualizar esse documento a qualquer momento, por esse motivo consulte-o com certa
                        frequência.
                    </Paragrafo>
                    <Titulo>9. Glossário</Titulo>
                    <Paragrafo>
                        9.1. Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor
                        entendimento:
                    </Paragrafo>
                    <Paragrafo>
                        <b>AntiSpam</b>: Sistema que evita correspondências não desejadas, como publicidade em massa, pelo bloqueio de
                        mensagens ou as movendo para pasta específica.
                    </Paragrafo>
                    <Paragrafo>
                        <b>Conta de Acesso</b>: Credencial de um <b>USUÁRIO</b> que permite acesso ao <b>SITE</b> da <b>PROTEJA SEU PET</b>,
                        definida pela combinação de login e senha, nas informações pessoais fornecidas pelo <b>USUÁRIO</b>.
                    </Paragrafo>
                    <Paragrafo>
                        <b>Layout</b>: Conjunto compreendido entre aparência, design e do <b>SITE</b> da <b>PROTEJA SEU PET</b>.
                    </Paragrafo>
                    <Paragrafo>
                        <b>Link</b>: Terminologia para endereço de Internet.
                    </Paragrafo>
                    <Paragrafo>
                        <b>USUÁRIOS</b>: Pessoas que acessam ou interagem com as atividades oferecidas pelo <b>SITE</b> da{" "}
                        <b>PROTEJA SEU PET</b> .
                    </Paragrafo>
                </List>
            </div>
                        
            <AceiteBar></AceiteBar>
        </Container>
    );
}
