import { Container, Typography, ListItem, ListItemAvatar, ListItemText, List, Button } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React from "react";

export default function Coberturas() {
    const [panelAberto, setPanelAberto] = React.useState(null);

    const handleChange = (panel) => (event) => {
        setPanelAberto(panelAberto !== panel ? panel : null);
    };

    const PaginaInicialListItem = ({ children, titulo, nome }) => {
        const icone = panelAberto === nome ? <Remove /> : <Add />;
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <img width="50px" alt="" src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/pata2.png" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Button variant="text" startIcon={icone} onClick={handleChange(nome)}>
                                <Typography variant="h6">{titulo}</Typography>
                            </Button>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography variant="body1">{panelAberto === nome ? children : ""}</Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    };

    return (
        <Container maxWidth="lg">
            <img
                alt=""
                style={{ width: "100%" }}
                src="https://protejaseupet-static.s3.amazonaws.com/pagina-inicial/pagina-inicial/coberturas.png"
            />
            <br />

            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <PaginaInicialListItem nome="panel1" titulo="Consulta Veterinária Emergencial">
                    <ul>
                        <li>
                            <b>R$ 200,00</b> - Em caso de lesão resultante de acidente e/ou doença do animal assistido, a Central de
                            Assistência providenciará a consulta veterinária na clínica mais próxima do local do evento.
                        </li>
                        <li>Doenças preexistentes não estão cobertas.</li>
                        <li>2 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel2" titulo="Cirurgia Veterinária">
                    <ul>
                        <li>
                            <b>R$ 5.000,00</b> - Em caso de Enfermidade ou Acidente, a assistência cobrirá custos com procedimentos
                            cirúrgicos simples até o limite contratado.
                        </li>
                        <li>A anestesia e honorários veterinários serão cobertos de acordo com seu plano.</li>
                        <li>Excluem-se desta cobertura os atendimentos a doenças preexistentes e partos. </li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel3" titulo="Consulta Veterinária">
                    <ul>
                        <li>
                            <b>R$ 200,00</b> - Quando solicitado pelo Cliente, a Central de Assistência agendará consulta veterinária, para
                            que o Animal Assistido receba os cuidados veterinários preventivos.
                        </li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel4" titulo="Exames de Imagens">
                    <ul>
                        <li>
                            <b>R$ 350,00</b> - Caso o animal assistido tenha utilizado os serviços de Consulta Veterinária Emergencial o
                            Cliente poderá acionar Central de Assistência e solicitar 1 (um) dos exames relacionados no próximo item:
                        </li>
                        <li>
                            Os exames cobertos são: Radiografia, Ultrassonografia, Eco cardiograma, Eletrocardiograma, Pressão Arterial e/ou
                            tomografia computadorizada. O Radiografia: Raio-X Simples do Abdômen, Tórax, Membros, Crânio, Coluna e/ou
                            Articulações. Não estão cobertos Raio X Contrastados. O Ultrassonografia: Para a realização de exame
                            ultrassonográfico adequado deve-se fazer a preparação do animal, com jejum alimentar de 8 horas, tosa da região
                            a ser examinada e evitar que o animal urine uma hora antes do exame. 6 o Eco cardiograma: permite obter
                            informações sobre o tamanho e a função das câmaras cardíacas, espessura das paredes, integridade valvar, padrões
                            de fluxo sanguíneo e alguns indicadores da função ventricular, auxiliando de maneira decisiva no diagnóstico e
                            no tratamento de cardiopatias. O exame tem duração de aproximadamente 1 hora. o Eletrocardiograma: o veterinário
                            registra a atividade elétrica do coração em relação ao tempo. É de fácil execução e proporciona informações
                            úteis ao diagnóstico e ao controle de doenças cardíacas e sistêmicas. O exame dura aproximadamente dez minutos e
                            é indolor. o Pressão Arterial: O exame dura alguns minutos e é indolor, causando pequeno desconforto. Deve-se
                            realizar uma pequena tosa na região do “pulso” e o animal deve estar calmo. A mensuração pode ficar prejudicada
                            em animais muito agitados ou agressivos, com hipotensão severa e animais obesos. o Tomografia Computadorizada:
                            Todos os exames solicitados pelo veterinário deverão ser entregues ao anestesista e ao radiologista antes do
                            início da tomografia. Solicita-se jejum total (água e comida) de 8 horas, prévio ao exame.
                        </li>
                        <li>Esse serviço será limitado de acordo com o valor contratado.</li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel5" titulo="Exames Laboratoriais">
                    <ul>
                        <li>
                            <b>R$ 350,00</b> - Caso o animal assistido tenha utilizado os serviços de Consulta Veterinária Emergencial o
                            Cliente poderá acionar Central de Assistência e solicitar os exames de acordo com guia médica.
                        </li>
                        <li>Esse serviço será limitado de acordo com o valor contratado.</li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel6" titulo="Envio de Ração à Domicílio">
                    <ul>
                        <li>
                            <b>R$ 80,00</b>- O Cliente poderá utilizar a Central de Assistência para solicitar o envio de ração à sua
                            residência.
                        </li>
                        <li>É necessário que no momento do atendimento o usuário saiba a marca, tipo e peso do produto.</li>
                        <li>
                            Este serviço poderá ser solicitado a Central de Assistência 24 horas por dia, mas o agendamento e o atendimento
                            obedecerão aos horários de funcionamento do prestador de serviço.
                        </li>
                        <li>Fica sob responsabilidade do Cliente o pagamento da ração, a assistência só se responsabiliza pelo envio.</li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel7" titulo="Funeral Individual">
                    <ul>
                        <li>
                            <b>R$ 2.000,00</b> - por intervenção, durante o período de vigência do Regulamento de Assistência.
                        </li>
                        <li>
                            Estão inclusos no serviço de assistência funeral, a urna de remoção, o transporte funeral (desde que o
                            sepultamento seja realizado dentro do município da Residência Habitual do Usuário) e a lápide do local (placa de
                            identificação do Animal Assistido).
                        </li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel8" titulo="Cerimonial PET">
                    <ul>
                        <li>
                            <b>R$ 500,00</b> - por intervenção, durante o período de vigência do Regulamento de Assistência.
                        </li>
                        <li>
                            Quando possível à realização deste serviço, o Usuário poderá acompanhar o velório de seu Animal Assistido em
                            salas especiais através de uma vídeo- chamada ou acesso às câmeras do estabelecimento. Já para o procedimento de
                            cremação, se fará necessário a presença do Usuário no local.
                        </li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel9" titulo="Traslado Funerário">
                    <ul>
                        <li>
                            <b>R$ 200,00</b> - Em caso de óbito do Animal Assistido, disponibilizará-se o transporte do corpo, garantindo o
                            pagamento das despesas de transporte até o local de inumação no Brasil.
                        </li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel10" titulo="Implantação de Microchip">
                    <ul>
                        <li>
                            O chip é um microcircuito eletrônico, do tamanho aproximado a de um grão de arroz, sendo assim possível
                            implantá-lo sob a pele de cães e gatos como uma forma de identificar o animal assistido, pois ele contém um
                            código exclusivo e inalterável que transmite informações específicas do seu Pet.
                        </li>
                        <li>
                            A central de Assistência encaminhará o cliente e seu Pet para uma clínica veterinária que se responsabilizará
                            pela implantação de um microchip de identificação no Animal Assistido, o serviço inclui ainda o cadastro dos
                            dados do Animal Assistido no banco de dados universal (Sistema de Identificação e Registro de Animais da América
                            Latina).
                        </li>
                        <li>
                            Estão cobertos nesse serviço, Somente os custos do veterinário, sendo o chip responsabilidade do cliente
                            adquirilo.
                        </li>
                        <li>Serviço realizado mediante agendamento prévio.</li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel11" titulo="Limpeza de Cálculos Dentais">
                    <ul>
                        <li>
                            <b>R$ 350,00</b> - A Limpeza de Tártaro somente será atendida quando solicitado por clínico veterinário.{" "}
                        </li>
                        <li>Serviço realizado mediante agendamento prévio.</li>
                        <li>O serviço não cobre nenhum outro tipo de tratamento odontológico.</li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel12" titulo="Taxi Dog">
                    <ul>
                        <li>
                            <b>R$ 80,00</b> - Caso o Cliente fique impossibilitado de levar seu animal até o Pet Shop, a Mondial Assistance
                            se responsabilizará pelo transporte de ida de volta até o local.
                        </li>
                        <li>A impossibilidade do Segurado deverá ser comprovada através de alguma documentação.</li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel13" titulo="Transporte Emergencial ao Veterinário">
                    <ul>
                        <li>
                            <b>R$ 120,00</b>- Em caso de lesão resultante de acidente e/ou doença do animal assistido, e uma vez que o
                            Cliente não tenha condições de leva-lo ao veterinário, a Mondial Assistance se responsabilizará pelo transporte
                            até a clínica veterinária mais próxima do local do evento.
                        </li>
                        <li>O meio de transporte mais adequado será definido pela Central de Assistência.</li>
                        <li>
                            Limite: o Transporte Emergencial ao Veterinário será realizado desde que exista disponibilidade Local e para
                            deslocamentos dentro do Município de residência do Cliente.
                        </li>
                        <li>1 intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel14" titulo="Segunda Opinião Veterinária">
                    <ul>
                        <li>
                            Caso o Cliente necessite de uma segunda opinião veterinária, uma equipe de médicos veterinários de diversas
                            especialidades dará o seu parecer.
                        </li>
                        <li>Para isso será necessário que o Cliente envie todos exames e laudos do Animal Assistido. </li>
                        <li>Sem limites de intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel15" titulo="Informações Sobre Vacinas">
                    <ul>
                        <li>
                            Informação sobre as vacinas necessárias ao animal, época para aplicação, locais e datas de vacinações públicas.
                        </li>
                        <li>Sem limites de intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel16" titulo="Informações Sobre Raças">
                    <ul>
                        <li>
                            Sempre que necessário, o usuário poderá utilizar nossa Central de Assistência para se informar sobre as raças de
                            cães existentes no Brasil e suas principais características.
                        </li>
                        <li>Sem limites de intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>

                <PaginaInicialListItem nome="panel17" titulo="Indicação de Hospitais 24 Horas">
                    <ul>
                        <li>
                            Caso o Cliente exceda a quantidade máxima de utilizações do serviço de Consulta Veterinária Emergencial a
                            Central de Assistência indicará o Hospital Veterinário 24 horas mais próximo ao local do evento.
                        </li>
                        <li>O custo referente ao atendimento será por conta do Cliente.</li>
                        <li>Sem limites de intervenção(ões) por período de vigência do Regulamento de Assistência.</li>
                    </ul>
                </PaginaInicialListItem>
            </List>
        </Container>
    );
}
